import cookies from "vue-cookies";

export default {
  user: {
    merchant: {},
    merchantOutlet: {},
    merchantUser: {},
  },
  registrationId: "",
  token: cookies.get("token"),
  tokenExpire: cookies.get("tokenExpire"),
  sandboxToken: cookies.get("sandboxToken"),
  sandboxTokenExpire: cookies.get("sandboxTokenExpire"),
  status: "",
  password_id: "",
  activatedStore: false,
  liveMode: true,
  currentTab: "",
  portName: {},
  activatedStoreModal: false,
  kycStatus: "",
  kycStatusModal: false,
  // temporary pending sandbox fix
  switchMode: true,
};
