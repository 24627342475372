import axios from "@/plugins/axios";
import cookies from "vue-cookies";

const getAuthorizationHeaders = (mode) => {
  return {
    Authorization: `Bearer ${mode ? cookies.get("token") : cookies.get("sandboxToken")}`,
  };
}

export const GET_WALLET = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/merchant-outlet/${id}/wallets`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant-outlet/${id}/wallets`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const GET_TOPUP_ACCOUNT = ({ liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/merchant/topup-account`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant/topup-account`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};


export const GET_MERCHANT_OUTLET = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/merchant-outlet/${id}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant-outlet/${id}`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const GET_QR_CODE = (payload) => {
  return axios.get(`/bnpl/merchant-outlet/${payload}/payment-link/qr-code`, {
    responseType: "blob",
  });
};

export const WITHDRAW_FROM_ZILLA = ({ id, data, liveMode }) => {
  return axios.put(
    liveMode
      ? `/bnpl/merchant-outlet/${id}/withdraw`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant-outlet/${id}/withdraw`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const CASHOUT_HISTORY = ({ payload, liveMode }) => {
  const id = payload.id;
  let config = {
    headers: getAuthorizationHeaders(liveMode),
    params: payload,
  };
  return axios.get(
    liveMode
      ? `/bnpl/merchant-outlet/${id}/settlement-wallet-history`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant-outlet/${id}/settlement-wallet-history`,
    config
  );
};

