<template>
  <div>
    <div
      class="relative cursor-pointer select-container"
      :class="[width, disabled && 'pointer-events-none']"
      tabindex="0"
      @blur="handleOptions"
    >
      <transition name="slideUp">
        <p v-if="value && type !== 'filter'" class="text-xs select-label">
          {{ placeholder }}
        </p>
      </transition>
      <div
        class="h-10"
        :class="[p, type !== 'filter' && 'line', disabled && 'disabled']"
        @click.stop="handleShowOptions"
        ref="line"
      >
        <p class="placeholder text-black">
          {{ text }}
        </p>
      </div>
      <svg
        v-if="!disabled"
        width="11"
        height="7"
        fill="none"
        class="transition-all"
        :class="{ rotate: showOptions }"
      >
        <path d="M.842 1l4.5 4.5 4.5-4.5" stroke="#000" />
      </svg>
      <!-- <transition name="fade"> -->
      <div
        class="options w-full bg-white p-1.5"
        v-show="showOptions"
        v-click-outside="handleOptions"
        ref="options"
      >
        <div class="options-body scrollbar p-2">
          <div id="arrow" data-popper-arrow></div>
          <div
            class="w-full"
            v-for="option in selectOptions"
            :key="option"
            @click.stop="chooseOption(option)"
          >
            <div class="flex items-center py-1 justify-between cursor-pointer">
              <p>{{ option }}</p>
              <img
                v-show="text === option"
                src="https://res.cloudinary.com/zillaafrica/image/upload/v1630797354/customer/Group_642_kmimow.svg"
                alt=""
                class="eligibile-icon w-4"
              />
            </div>

            <Hr class="my-0" />
          </div>
        </div>
      </div>
      <!-- <div class="error-text">
      <transition name="fade">
        <p class="text-brandRed text-xs mt-1" v-if="error">
          {{ errorText }}
        </p>
      </transition>
    </div> -->
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
  import { createPopper } from "@popperjs/core/lib/popper-lite.js";
  import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow.js";
  // import { detectOverflow } from "@popperjs/core"
  import flip from "@popperjs/core/lib/modifiers/flip.js";
  import Hr from "@/UI/Hr";
  export default {
    components: {
      Hr,
    },
    props: {
      width: {
        type: String,
        default: "w-auto",
        required: false,
      },
      label: {
        type: String,
        default: "",
        required: false,
      },
      p: {
        type: String,
        default: "py-2",
      },
      type: {
        type: String,
        default: "",
        required: false,
      },
      reduce: {
        type: Function,
      },
      options: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: "Select",
        required: false,
      },
      value: {
        type: String,
        default: "",
        required: false,
      },
      validation: {
        type: Boolean,
        default: true,
        required: false,
      },
      errorText: {
        type: String,
        default: "error",
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    data: () => ({
      showOptions: false,
      isObject: false,
      //  text: "Select",
      error: false,
    }),
    computed: {
      selectOptions() {
        //   if it is a type of object
        if (typeof this.options[0] === "object" && this.options !== null) {
          this.isObject = true;
          // if the label prop was passed
          if (this.label) {
            return this.options.map((item) => item[this.label]);
          } else {
            return this.options.map((item) => item.label);
          }
        } else {
          return this.options;
          // this.isObject = false;
        }
      },
      text() {
        if (this.placeholder || this.value) {
          if (this.value) {
            // check if it is an object else return the value
            // loop through the array to fimd the label and return the label or prop based label
            if (typeof this.options[0] === "object" && this.options !== null) {
              const index = this.options.findIndex(
                // using the reduce function passed as prop to locate the nested value
                (option) => this.reduce(option) === this.value
              );
              return this.selectOptions[index];
            } else {
              return this.value;
            }
          } else {
            return this.placeholder || "Select";
          }
        }
      },
    },
    // watch: {
    //   text(value) {
    //     console.log(value, "select", this.value);
    //   },
    // },
    mounted() {
      // console.log("select", this.value);
      // this.handlePreValue();
      this.handlePositionChange();
    },
    methods: {
      // displayInput() {
      //   if (!this.validation) {
      //     // console.log("input", this.validation);
      //     this.error = true;
      //     this.$emit("valid", false);
      //   } else {
      //     this.error = false;
      //     this.$emit("valid", true);
      //   }
      // },
      chooseOption(value) {
        // console.log("component", value);
        // this.text = value;
        this.showOptions = false;
        if (typeof this.options[0] === "object" && this.options !== null) {
          if (this.reduce) {
            //   get index of the selected value
            const index = this.selectOptions.indexOf(value);
            // use the index to locate the object in the options array
            this.$emit("input", this.reduce(this.options[index]));
          }
        } else {
          this.$emit("input", value);
        }
      },
      clickTarget(e) {
        if (e.target.className.includes("select-container")) {
          this.showOptions = false;
        }
      },
      handleOptions() {
        this.showOptions = false;
        if (!this.validation) {
          // console.log("input", this.validation);
          this.error = true;
          this.$emit("valid", false);
        } else {
          this.error = false;
          this.$emit("valid", true);
        }
      },
      // handleFocus() {
      //   if (!this.validation) {
      //     // console.log("input", this.validation);
      //     this.error = true;
      //     this.$emit("valid", false);
      //   } else {
      //     this.error = false;
      //     this.$emit("valid", true);
      //   }
      // },
      handlePositionChange() {
        const line = this.$refs.line;
        const options = this.$refs.options;
        createPopper(line, options, {
          placement: "bottom",
          // modifiers: [flip],
          options: {
            offset: [0],
          },
        });
      },
      // handlePreValue() {
      //   // to set v-model for the component
      //   if (this.placeholder || this.value) {
      //     if (this.value) {
      //       // check if it is an object else return the value
      //       // loop through the array to fimd the label and return the label or prop based label
      //       if (typeof this.options[0] === "object" && this.options !== null) {
      //         const index = this.options.findIndex(
      //           // using the reduce function passed as prop to locate the nested value
      //           (option) => this.reduce(option) === this.value
      //         );
      //         this.text = this.selectOptions[index];
      //       } else {
      //         this.text = this.value;
      //       }
      //     } else {
      //       this.text = this.placeholder;
      //     }
      //   }
      // },
      handleShowOptions() {
        // createPopper.update();
        const line = this.$refs.line;
        const options = this.$refs.options;

        const instance = createPopper(line, options);
        const modal = document.querySelector(".modal-content");
        instance.setOptions({
          placement: "bottom",
          modifiers: [flip, preventOverflow],
          // padding: 8,
          offset: [0, 20],
          // strategy: "fixed",
        });
        // console.log(instance);
        this.showOptions = !this.showOptions;
        this.$nextTick(() => {
          instance.update();
        });
      },
    },
  };
</script>
<style scoped>
  @import "~@/assets/styles/animation.css";
  input {
    border-bottom: 1px solid black;
    font-size: 18px;
    background-color: transparent;
    position: relative;
    z-index: 5;
    -webkit-appearance: none;
    border-radius: 0;
  }
  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  #arrow {
    visibility: hidden;
  }
  .select-label {
    position: absolute;
    left: 0;
    top: -1rem;
  }
  #arrow::before {
    visibility: visible;
    content: "";
    transform: rotate(45deg);
  }
  /* .placeholder {
    color: #9d9d9d;
  } */

  .line {
    /* height: 2rem; */
    border-bottom: 1px solid black;
  }
  svg {
    position: absolute;
    top: 1rem;
    right: 0;
    cursor: pointer;
  }
  .options {
    box-shadow: 0px 6.65584px 39.9351px rgba(128, 70, 251, 0.2);
    border-radius: 10px;
    z-index: 20;
    min-width: 15rem;

    /* top: 0.3rem; */
    /* position: absolute; */
    /* margin-top: 0.3rem; */
    /* left: 0 !important; */
    /* top: 0; */
  }
  .options-body {
    max-height: 18rem;
    overflow-y: auto;
  }
  .disabled {
    border-bottom: 1px solid #f2edfd;
  }
  .display {
    display: none;
  }
  /* .options::-webkit-scrollbar {
    width: 5px !important;
  }
  .options::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d3d3d3;
    border-radius: 10px;
  }
  .options::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  } */
  .rotate {
    transform: rotate(180deg);
  }
</style>
