import axios from "@/plugins/axios";
import cookies from "vue-cookies";
import router from "@/router";
import * as Sentry from "@sentry/vue";


import {
  LOGIN,
  REGISTER,
  SUBMIT_PASSWORD,
  SUBMIT_BUSINESS_INFO,
  FORGOT_PASSWORD,
  PASSWORD_OTP,
  PASSWORD_RESET,
  NEW_PASSWORD,
  GET_MERCHANT,
  PORT_CUSTOMER,
} from "@/utils/api/auth";
// import { TOGGLE_PROGRAMMATIC_ACCESS } from "@/utils/api/settings.js";

export default {
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      REGISTER(user)
        .then((response) => {
          commit("SET_REGISTRATIONID", response.data.data.registrationId);
          router.push("/otp");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyOtp({ }, user) {
    return new Promise((resolve, reject) => {
      SUBMIT_PASSWORD(user)
        .then((response) => {
          const token = response.data.data.authResponse.token;
          cookies.set("token", token, "2h");
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          router.push("/about");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  submitBusinessInfo({ commit }, merchant) {
    return new Promise((resolve, reject) => {
      SUBMIT_BUSINESS_INFO(merchant)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  login({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit("AUTH_REQUEST");
      LOGIN(user)
        .then((response) => {
          const token = response?.data?.data?.authResponse?.token;
          const tokenExpire = response?.data?.data?.authResponse?.tokenExpireAt;
          cookies.set("token", token);
          cookies.set("tokenExpire", tokenExpire);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          const sandboxToken = response?.data?.data?.sandboxAuthResponse?.token;
          const sandboxTokenExpire =
            response?.data?.data?.sandboxAuthResponse?.tokenExpireAt;
          cookies.set("sandboxToken", sandboxToken);
          cookies.set("sandboxTokenExpire", sandboxTokenExpire);
          if (response?.data?.data?.userCategory === "MERCHANT_REGISTRATION") {
            dispatch(
              "notification/showAlert",
              {
                description: "Please complete your registration process",
                display: true,
                type: "error",
              },
              { root: true }
            );
            let step =
              response.data?.data?.merchantRegistration
                ?.completedRegistrationSteps;
            if (
              step.includes("SUBMIT_EMAIL") &&
              step.includes("VERIFY_EMAIL")
            ) {
              router.push("/about");
            }
            reject(response);
          } else if (
            response?.data?.data?.userCategory === "CUSTOMER_REGISTRATION" ||
            response?.data?.data?.userCategory === "CUSTOMER"
          ) {
            // dispatch(
            //   "notification/showAlert",
            //   {
            //     description:
            //       "This account belongs to customer. Sign up as a merchant.",
            //     display: true,
            //     type: "error",
            //   },
            //   { root: true }
            // );
            resolve(response?.status);
          } else if (response?.data?.data?.userCategory === "MERCHANT_USER") {
            commit("AUTH_SUCCESS", token);
            commit("setUserDetails", response.data.data.merchantUser);
            commit("SET_TOKEN_EXPIRE", tokenExpire);
            let dashboardMode =
              response?.data?.data?.merchantUser?.merchant?.dashboardMode;
            if (dashboardMode === "LIVE") {
              commit("SET_LIVE_MODE", true);
            } else {
              commit("SET_LIVE_MODE", false);
            }
            let kycStatus = response?.data?.data?.merchantUser?.merchant?.kycStatus;
            commit("SET_KYC_STATUS", kycStatus);
            if (kycStatus === 'PENDING') {
              commit("SET_KYC_STATUS_MODAL", true);
            }
            let step =
              response?.data?.data?.merchantUser?.merchant
                ?.completedMerchantSetupSteps;
            if (
              step?.includes("PROVIDED_BUSINESS_PRIMARY_DETAILS") &&
              step?.includes("PROVIDED_BUSINESS_SECONDARY_DETAILS")
            ) {
              router.push("/");
              commit('SET_ACTIVATE_STORE_MODAL', false);
            } else {
              router.push("/activate-store");
              commit('SET_ACTIVATE_STORE_MODAL', true);
            }
            Sentry.setUser({ email: response.data.data.merchantUser?.merchantUser.email });
            window && window["clarity"] && window["clarity"]("set", "email",  response?.data?.data?.merchantUser?.merchantUser.email  );
            window && window["clarity"] && window["clarity"]("set", "id",  response?.data?.data?.merchantUser?.merchantUser.id  );
            resolve(response);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(
              "notification/showAlert",
              {
                display: true,
                type: "error",
                description: error.response.data.message,
              },
              { root: true }
            );
          }
          commit("AUTH_ERROR", error);
          reject(error);
        });
    });
  },
  setPortName({ commit }, payload) {
    commit("SET_PORT_NAME", payload);
  },
  portCustomer({ }, payload) {
    return new Promise((resolve, reject) => {
      PORT_CUSTOMER(payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setUserDetails({ commit }, payload) {
    commit("setUserDetails", payload);
  },
  setCurrentTab({ commit }, payload) {
    commit("setCurrentTab", payload);
  },

  getLoggedInMerchant({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_MERCHANT(payload)
        .then((response) => {
          commit("setMerchantDetails", response.data.data);
          // console.log(response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateInfo({ commit }, payload) {
    if (payload.dashboardMode === "LIVE") {
      commit("SET_LIVE_MODE", true);
    } else {
      commit("SET_LIVE_MODE", false);
    }
    commit("setMerchantDetails", payload);
  },

  updateAccountInfo({ commit }, payload) {
    commit("setMerchantBankDetails", payload);
  },

  activateStore({ commit }, payload) {
    commit("ACTIVATE_STORE", payload);
  },

  closeActivateStoreModal({ commit }, payload) {
    commit('SET_ACTIVATE_STORE_MODAL', false);
  },

  logout({ commit }) {
    commit("AUTH_LOGOUT");
    cookies.remove("token");
    localStorage.clear();
    router.push("/login");
  },

  forgotPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      FORGOT_PASSWORD(payload)
        .then((response) => {
          commit("CHANGE_PASSWORD_ID", response.data.data.id);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setOtpId({ commit }, payload) {
    commit("CHANGE_PASSWORD_ID", payload);
  },

  verifyPasswordResetOtp({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_OTP(payload)
        .then((response) => {
          router.push("/create-password");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  completePasswordReset({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PASSWORD_RESET(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changePassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      NEW_PASSWORD(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setMode({ commit }, payload) {
    commit("SET_LIVE_MODE", payload);
  },

  resetKycStatus({ commit }) {
    commit("SET_KYC_STATUS", "");
    commit("SET_KYC_STATUS_MODAL", false);
  },

  setKycStatus({ commit }, payload) {
    commit("SET_KYC_STATUS", payload);
  },

  setKycStatusModal({ commit }, payload) {
    commit("SET_KYC_STATUS_MODAL", payload);
  }
};
