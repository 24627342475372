<template>
  <div
    class="
      w-full
      md:min-h-screen
      h-full
      register-container
      grid
      place-items-center
    "
  >
    <div class="w-10/12 container-register pb-4 mx-auto">
      <div class="register-form">
        <h2
          class="
            text-lg text-center
            lg:text-left
            md:text-2xl
            font-semibold
            mt-6
            lg:mt-0
          "
        >
          Merchants’ sign in
        </h2>
        <!-- <p class="mt-6 md:mt-0 text-grey">Get in to your account</p> -->
        <form @submit.prevent="loginUser">
          <Input
            type="email"
            width="w-full"
            id="email"
            placeholder="What's your email?"
            class="mt-14"
            v-model="user.username"
            errorText="Please enter a valid email address"
            :validation="rules.username"
            @valid="valid.username = $event"
          />
          <Input
            type="password"
            width="w-full"
            id="password"
            placeholder="What's your password"
            class="mt-11"
            :revealPassword="true"
            v-model="user.secret"
            errorText="Password field cannot be empty"
            :validation="rules.secret"
            @valid="valid.secret = $event"
          />

          <span class="text-black mt-5 flex justify-end underline text-sm">
            <router-link to="/forgot-password">Forgot password?</router-link>
          </span>
          <Button
            text="Sign in"
            class="mt-12"
            :shadow="true"
            width="w-full"
            :loading="loading"
            :disabled="disableButton"
            type="submit"
          />
        </form>
        <div class="mt-10">
          <p class="text-sm text-center text-black">
            New to zilla?
            <router-link to="/register">
              <span class="text-black underline"
                >Create a merchant account</span
              ></router-link
            >
          </p>
        </div>
      </div>
    </div>
    <!-- modal prompt for if login attempt used shopper's details-->
    <Modal
      :display="showLoginPrompt.display"
      @close="showLoginPrompt.display = false"
    >
      <transition name="slideX" mode="out-in">
        <div class="text-center" v-if="showLoginPrompt.firstPrompt" key="first">
          <h3 class="text-center text-black text-xl font-bold">
            This details belongs to a customer
          </h3>
          <p class="text-grey text-lg mt-4">
            You can either login to your shopper’s dashboard or create a
            merchant account using same details.
          </p>
          <Button
            text="Go to shopper's Login"
            width="w-full"
            class="mt-16"
            @click="handleLoginPrompt"
          />
          <Button
            outline
            text="Create Merchant Account instead"
            width="w-full"
            class="mt-4"
            @click="showLoginPrompt.firstPrompt = false"
          />
        </div>
        <div class="text-center" v-else key="second">
          <h3 class="text-center text-black text-xl font-bold">
            You are creating a merchant account
          </h3>
          <p class="text-grey text-lg mt-4">
            We will need some extra details from you to complete the process
          </p>
          <Button
            text="Proceed"
            width="w-full"
            class="mt-16"
            @click="handleLoginPrompt('register')"
          />
          <Button
            outline
            text="Cancel"
            width="w-full"
            class="mt-4"
            @click="showLoginPrompt.display = false"
          />
        </div>
      </transition>
    </Modal>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import Input from "@/UI/Input";
  import Button from "@/UI/Button";
  export default {
    components: {
      Input,
      Button,
      Modal: () => import("@/UI/Modal"),
    },
    data() {
      return {
        user: {
          username: "",
          secret: "",
          loginAs: "MERCHANT_USER",
        },
        loading: false,
        valid: {
          username: false,
          secret: false,
        },
        showLoginPrompt: {
          display: false,
          firstPrompt: true,
        },
      };
    },
    computed: {
      rules() {
        return {
          username: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.user.username
          ),
          secret: this.user.secret.length !== 0,
        };
      },
      disableButton() {
        return Object.values(this.rules).includes(false);
      },
    },
    methods: {
      ...mapActions("auth", ["login", "setUserDetails", "setMode"]),
      ...mapActions("notification", ["showAlert"]),
      loginUser() {
        this.loading = true;
        const user = { email: this.user.username, password: this.user.secret };
        this.setUserDetails(user);

        this.login(this.user)
          .then((data) => {
            if (data === 206) {
              this.showLoginPrompt.firstPrompt = true;
              this.showLoginPrompt.display = true;
            }
            // this.$router.go();
            // }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      handleLoginPrompt(mode) {
        this.showLoginPrompt.display = false;
        setTimeout(() => {
          if (mode === "register") {
            this.$router.push(`/register?port=true`);
            const user = {
              email: this.user.username,
              password: this.user.secret,
            };
            this.setUserDetails(user);
          } else {
            // setTimeout(() => {
            // this.$router.go();
            // }, 200);
            window.open(`${process.env.VUE_APP_CUSTOMER_URL}`, "_blank");
          }
        }, 700);
      },
    },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  .register-container h3 {
    font-size: 35px;
  }
  .register-container p {
    line-height: 30.15px;
  }
  .container-register {
    max-width: 33.831rem;
  }
</style>
