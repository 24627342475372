<template>
  <div id="app">
    <div>
      <Alert />
      <Toast />
      <Loader />
      <CreateOrder />
      <UpdatePhoneNumber />
      <component :is="layout">
        <transition :name="handleTransition" mode="out-in">
          <router-view />
        </transition>
      </component>
    </div>
  </div>
</template>
<script>
const default_layout = "default";
import Alert from "@/UI/AlertBox";
import Button from "@/UI/Button";
import CreateOrder from "@/components/CreateOrder";
import UpdatePhoneNumber from "@/components/Settings/UpdatePhoneNumber";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    Alert,
    CreateOrder,
    UpdatePhoneNumber,
    Button,
    Toast: () => import("@/UI/Toast"),
    Loader: () => import("@/UI/Loader")
  },
  data: () => ({
    loading: false
  }),
  computed: {
    handleTransition() {
      return this.layout === "auth-layout" ? "slideX" : null;
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    isDesktop() {
      if (window.innerWidth >= 760 || screen.width >= 760) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState({
      merchant: state => state?.auth?.user?.merchantUser
    }),
    ...mapGetters("auth", ["isAuthenticated"])
  },
  watch: {
      isAuthenticated(value) {
        if (value === true) {
          window.Atlas.identify({
            userId: `${this.merchant?.merchantUser?.id}`,
            name: `${this.merchant?.merchantUser.merchantName}`,
            email: `${this.merchant?.merchantUser?.email}`,
          });
        }
      },
    },
  methods: {
    goToWebsite() {
      this.loading = true;
      setTimeout(() => {
        window.location.href = `${process.env.VUE_APP_WEBSITE}`;
      }, 500);
    },
  },
  watch: {
    isAuthenticated(value) {
      if (value) {
        window.Atlas.identify({
          userId: `${this.merchant.id}`,
          name: `${this.merchant.firstName} ${this.merchant.lastName}`,
          email: `${this.merchant.email}`
        });
      }
    }
  }
};
</script>
<style>
@import "./assets/main.css";
@import "~@/assets/styles/animation.css";
/* @import "./assets/css/fonts.css"; */
#app {
  font-family: "Inter", Helvetica;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gilroy", Helvetica;
}
p,
a,
span,
td,
tr,
button {
  font-family: "Inter", Helvetica;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p {
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  p {
    font-size: 14px;
  }
}
</style>
