// import axios from "@/plugins/axios";
import { GET_ALL_PURCHASES, GET_REFUND_BREAKDOWN } from "@/utils/api/purchase";

export default {
  getAllPurchases({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_PURCHASES(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PURCHASES", data.data)
            : commit("GET_PURCHASES", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRefundBreakdown({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_REFUND_BREAKDOWN({
        liveMode: payload.liveMode,
        id: payload.id
      })
        .then(({ data }) => {
          console.log(data);
          commit("UPDATE_REFUND_BREAKDOWN", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clearBreakdown({ commit }) {
    commit("CLEAR_BREAKDOWN");
  },
};
