<template>
  <div
    class="
      w-full
      md:min-h-screen
      h-full
      register-container
      grid
      place-items-center
    "
  >
    <div class="w-10/12 container-register flex flex-col pb-4 mx-auto">
      <div class="register-form" key="form">
        <h2
          class="
            text-lg text-center
            lg:text-left
            md:text-2xl
            font-semibold
            mt-6
            lg:mt-0
          "
        >
          We see you are ready for more sales.
          <span class="text-grey">Let’s go!</span>
        </h2>
        <form @submit.prevent="initiateRegistration">
          <Input
            type="text"
            id="first_name"
            width="w-full"
            placeholder="What's your First name?"
            class="mt-14"
            v-model="user.firstName"
            errorText="First name is required"
            :validation="rules.firstName"
            @valid="valid.firstName = $event"
          />
          <Input
            type="text"
            id="last_name"
            width="w-full"
            placeholder="What's your Last name"
            class="mt-11"
            v-model="user.lastName"
            errorText="Last name is required"
            :validation="rules.lastName"
            @valid="valid.lastName = $event"
          />
          <Input
            v-if="!port"
            type="email"
            id="email"
            width="w-full"
            placeholder="What's your email?"
            class="mt-11"
            v-model="user.email"
            errorText="Please enter a valid email address"
            :validation="rules.email"
            @valid="valid.email = $event"
          />
          <Input
            v-if="!port"
            type="password"
            id="password"
            width="w-full"
            placeholder="Create password"
            class="mt-11"
            revealPassword
            required
            v-model="user.password"
            errorText="Password must have at least 8 characters, a lowercase letter, an uppercase letter, a number and a special character"
            :validation="rules.password"
            @valid="valid.password = $event"
          />
          <Button
            text="Create account"
            class="mt-12"
            shadow
            width="w-full"
            :loading="loading"
            :disabled="disableButton"
            type="submit"
          />
        </form>
      </div>
      <p class="text-sm text-center mt-10 text-black">
        Already have an Account?
        <router-link to="/login">
          <span class="text-black underline">Sign In</span></router-link
        >
      </p>
      <div class="flex items-end h-full">
        <p class="text-black text-xs mt-4 text-center">
          By creating an account, I confirm that I have read and understood the
          Zilla
          <a
            href="https://usezilla.notion.site/Data-Privacy-Protection-Cookie-Policy-fd834fd91648458fb5bbf68acce27242"
            class="text-black underline"
            target="blank"
            >Privacy Policy</a
          >
          and
          <a
            href="https://usezilla.notion.site/Terms-of-use-Merchant-989744ceb32c4330aa07c6868d162032"
            class="underline"
            target="blank"
          >
            Terms of Use</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import Input from "../../../UI/Input";
  import Button from "@/UI/Button";
  export default {
    components: {
      Input,
      Button
    },
    data() {
      return {
        user: {
          email: "",
          password: "",
          firstName: "",
          lastName: "",
        },
        port: false,
        loading: false,
        valid: {
          firstName: false,
          lastName: false,
          email: false,
          password: false,
        },
      };
    },
    computed: {
      registrationId() {
        return this.$store.state.registrationId;
      },
      rules() {
        return {
          firstName: this.user.firstName.length !== 0,
          lastName: this.user.lastName.length !== 0,
          email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.user.email
          ),
          password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
            this.user.password
          ),
        };
      },
      disableButton() {
        if (this.port) {
          if (!this.rules.firstName || !this.rules.lastName) {
            return true;
            // return this.rules.firstName && this.rules.lastName;
          }
        } else {
          return Object.values(this.rules).includes(false);
        }
      },
    },
    mounted() {
      if (this.$route?.query?.port) {
        this.port = true;
      }
      if (this.$route?.query?.email) {
        this.user.email = this.$route.query.email;
      }
    },
    methods: {
      ...mapActions("auth", ["register", "setUserDetails", "setPortName"]),
      ...mapActions("notification", ["showAlert"]),
      initiateRegistration() {
        if (this.port) {
          this.setPortName(this.user);
          this.$router.push("/about?port=true");
        } else {
          this.loading = true;
          this.register({
            email: this.user.email,
            referralCode: this.$cookies.get("referralCode"),
          })
            .then(() => {
              this.setUserDetails(this.user);
              this.loading = false;
            })
            .then(() => {
              this.setUserDetails(this.user);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      },
    },
  };
</script>
<style scoped>
  @import "../../../assets/styles/animation.css";
  /* .register-container {
    min-height: 100vh;
  } */
  .register-container h3 {
    font-size: 35px;
  }
  .register-container p {
    line-height: 30.15px;
  }
  .container-register {
    max-width: 33.831rem;
  }
</style>
