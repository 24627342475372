<template>
  <Card p="p-6 md:p-8" class="overflow-hidden">
    <div v-if="loader" class="py-3">
      <div class="flex justify-between items-center overflow-x-auto mb-3">
        <Skeleton height="1.3rem" width="10.2rem" />
      </div>
      <Skeleton height="1.4rem" width="60rem" />
      <!-- <div class="metric-container mt-6 ">
        <div class="metric__item ">
          <div>
            <Skeleton height="1rem" width="7rem" class="mb-3" />
            <Skeleton height="1.8rem" width="9rem md:10.2rem" />
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item ">
          <div>
            <Skeleton height="1rem" width="7rem" class="mb-3" />
            <Skeleton height="1.8rem" width="9rem md:10.2rem" />
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item ">
          <div>
            <Skeleton height="1rem" width="7rem" class="mb-3" />
            <Skeleton height="1.8rem" width="9rem md:10.2rem" />
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item">
          <div>
            <Skeleton height="1rem" width="7rem" class="mb-3" />
            <Skeleton height="1.8rem" width="9rem md:10.2rem" />
          </div>
        </div>
      </div> -->
    </div>

    <div v-else class="py-3">
      <div class="flex justify-between md:justify-start items-center overflow-x-auto">
        <h4 class="font-extrabold md:text-lg">Your store activity</h4>
        <p class="text-brandPurple md:ml-6 rounded-full bg-lightPurple text-xs md:text-sm px-3 md:px-6 py-2">Coming soon</p>
      </div>
      <p class="pt-2 text-dark">Welcome to your store! We are very excited to have you on board. Watch a special welcome video we made just for you here</p>
      <!-- <div class="metric-container mt-6 ">
        <div class="metric__item">
          <div>
            <p class="md:font-semibold text-sm text-lightPurple-2 ">
              Total settlement
            </p>
            <p class="text-black text-xl md:text-2xl font-semibold">******</p>
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item ">
          <div>
            <p class="md:font-semibold text-lightPurple-2 text-sm">
              Total order value
            </p>
            <p class="text-black text-xl md:text-2xl font-semibold">******</p>
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item ">
          <div>
            <p class="md:font-semibold text-lightPurple-2 text-sm ">
              Total order volume
            </p>
            <p class="text-black text-xl md:text-2xl font-semibold">******</p>
          </div>
        </div>
        <div class="line h-16 bg-lightPurple-2 w-px"></div>
        <div class="metric__item">
          <div>
            <p class="md:font-semibold text-lightPurple-2 text-sm ">
              Growth rate
            </p>
            <p class="text-black text-xl md:text-2xl font-semibold">****</p>
          </div>
        </div>
      </div> -->
    </div>
  </Card>
</template>
<script>
export default {
  data: () => ({
    loader: true,
  }),
  props: {
    analytics: Object,
  },
  mounted() {
    this.wait();
  },
  methods: {
    async wait() {
      let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
      await sleep(1000);
      this.loader = false;
    },
  },
};
</script>
<style scoped>
.active-success {
  color: #97f380;
}

.inactive-success {
  color: #c9252d;
}

.impression-success {
  color: #21bfaf;
}
.enrollment-success {
  color: #1d66ff;
}

.metric-container {
  display: flex;
  overflow-x: auto !important;
  /* align-items: center;
    justify-content: space-between; */
}
.line {
  margin-right: 3.2rem;
}
.metric__item {
  display: flex;
  align-items: center;
  margin-right: 3.2rem;
}
/* .item {
  width: 15rem;
} */
@media only screen and (max-width: 1024px) {
  .metric-container {
    width: 100%;
    overflow-x: auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .metric__item {
    margin-top: 1rem;
    margin-right: 1rem;
    /* border-right: 1px solid; */
  }

  .item img {
    height: 5rem;
    width: 5rem;
    display: none;
  }
  .line {
    display: none;
  }
}
</style>
