<template>
  <div
    class="w-full md:min-h-screen register-container grid place-items-center"
    :class="[showMessage ? '' : 'about-container']"
  >
    <div class="w-10/12 container-register pb-4 mx-auto">
      <transition name="slideUp" mode="out-in">
        <div class="register-form" v-if="showMessage" key="form">
          <h2
            class="
              text-lg text-center
              lg:text-left
              md:text-2xl
              font-semibold
              mt-6
              lg:mt-0
            "
          >
            Tell us about your business
          </h2>
          <form @submit.prevent="sendPhoneOtp">
            <div class="relative">
              <Input
                type="text"
                width="w-full"
                placeholder="Business name"
                class="mt-14"
                v-model="merchant.businessName"
                hideErrorIcon
                errorText="Business name is required"
                :validation="rules.businessName"
                @valid="valid.businessName = $event"
              />
              <p class="text-sm text-black">
                This should be your full business name as it appears on all your
                documents
              </p>
            </div>

            <Input
              id="address"
              type="text"
              width="w-full"
              placeholder="Business Address"
              class="mt-11"
              v-model="merchant.businessAddress"
              @blur="validateAddress()"
              @focus="handleError()"
            />
            <transition name="fade-out">
              <p v-if="errorOnAddress" class="text-brandRed text-xs">
                Please select a valid address from the dropdown
              </p>
            </transition>

            <Input
              type="text"
              width="w-full"
              placeholder="Add more details to this address (if any)"
              class="mt-11"
              v-model="merchant.additionalAddressDetails"
            />

            <div class="grid grid-cols-2 gap-4">
              <Select
                class="mt-11"
                width="w-full"
                label="name"
                placeholder="State"
                :options="states"
                :reduce="(category) => category.name"
                v-model="merchant.businessState"
              />

              <Select
                class="mt-11"
                width="w-full"
                label="name"
                placeholder="LGA"
                :options="lga"
                :reduce="(category) => category.name"
                v-model="merchant.businessLga"
              />
            </div>

            <vue-tel-input
              v-if="!port"
              v-model="merchant.phoneNumber"
              :enabled-country-code="false"
              :input-options="{ showDialCode: false, tabindex: 0 }"
              :required="true"
              :valid-characters-only="true"
              :mode="'international'"
              @input="checkPhoneNumber"
              defaultCountry="NG"
              class="mt-14"
              wrapper-classes="border-0 focus:outline-none pl-12 text-brand_dark_blue"
              :inputOptions="options"
            ></vue-tel-input>
            <p
              :style="{
                visibility: phoneIsValid === false ? 'visible' : 'hidden',
              }"
              class="text-brandRed text-xs py-1"
            >
              Invalid phone number for the country code selected
            </p>

            <Button
              text="Continue"
              class="mt-10"
              :shadow="true"
              width="w-full"
              :loading="loading"
              :disabled="disableButton"
              type="submit"
            />
          </form>
          <div class="mt-5">
            <p class="text-sm text-center text-grey font-semibold">
              Already have an Account?
              <router-link to="/login" class="text-black">Sign In</router-link>
            </p>
          </div>
        </div>
        <div v-if="!showMessage" class="message h-full" key="message">
          <img
            class="h-32 w-32 mx-auto"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
            alt="success"
          />
          <h2 class="text-center mt-8 text-lg lg:text-2xl font-semibold">
            You are in!
          </h2>

          <p class="mt-2.5 text-grey text-center">
            The zilla experience awaits you. Please proceed to complete your setup
          </p>

          <Button
            text="Go to your zilla account"
            class="mt-14"
            width="w-full"
            @click="loginUser()"
            :loading="dashboardLoading"
          />
        </div>
      </transition>
    </div>

    <Modal
      :display="showOtpModal"
      @close="(showOtpModal = false), (showResendButton = false)"
      title="Enter OTP"
    >
      <h5 class="text-dark text-lg mt-3">
        We sent an SMS and a whatsapp message to {{ merchant.phoneNumber }}.
      </h5>

      <div class="relative">
        <Input
          class="mt-8"
          placeholder="Enter OTP"
          width="w-full"
          v-model="merchant.phoneOtp"
          type="number"
          errorText="OTP must be 6 characters"
          :validation="otprules.otpValid"
          @valid="otpValid = $event"
          hideErrorIcon
        />
        <SecondaryButton
          v-if="showResendButton"
          class="resend-otp z-10"
          radius="rounded-full"
          fontSize="text-xs"
          background="bg-lightPurple"
          color="brandPurple"
          text="Resend OTP"
          :loading="resendLoading"
          width="w-28"
          @click="resendPhoneOtp"
        />
      </div>

      <Button
        text="Verify phone number"
        width="w-full"
        class="my-8"
        @click="updateBusinessInfo"
        :loading="verifyLoading"
        :disabled="disableOtpButton"
      />
    </Modal>
  </div>
</template>
<script>
import { PHONE_OTP } from "@/utils/api/auth.js";
import { mapActions, mapState } from "vuex";
import Input from "../../../UI/Input";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/Button";
import Location from "@/database/location.json";
import Modal from "@/UI/Modal";
import Select from "@/UI/Select";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    Input,
    Button,
    Select,
    VueTelInput,
    Modal,
    SecondaryButton,
  },
  data() {
    return {
      showMessage: true,
      merchant: {
        businessName: "",
        businessAddress: "",
        businessState: "",
        businessLga: "",
        phoneNumber: "",
        phoneOtp: "",
      },
      loading: false,
      options: [],
      states: [],
      lga: [],
      valid: {
        businessName: false,
        businessAddress: false,
        businessState: false,
        businessLga: false,
      },
      dashboardLoading: false,
      isAddressValid: false,
      errorOnAddress: false,
      showOtpModal: false,
      phoneIsValid: true,
      canSubmit: false,
      options: { placeholder: "Phone number" },
      otpValid: false,
      verifyLoading: false,
      showResendButton: false,
      resendLoading: false,
      port: false,
    };
  },
  computed: {
    ...mapState({
      portUser: (state) => state?.auth?.portName,
    }),
    ActiveUser() {
      return this.$store.getters["auth/ActiveUser"];
    },
    rules() {
      return {
        phoneNumber: this.canSubmit === true,
        businessName: this.merchant.businessName.length !== 0,
        businessAddress: this.isAddressValid,
        businessState: this.merchant.businessState.length !== 0,
        businessLga: this.merchant.businessLga.length !== 0,
      };
    },
    otprules() {
      return {
        otpValid: this.merchant?.phoneOtp?.length === 6,
      };
    },

    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otprules).includes(false);
    },
  },
  watch: {
    "merchant.businessState"(value) {
      this.states.forEach((state) => {
        if (state.name === value) {
          this.lga = state.locals.map((lga) => lga);
        }
      });
    },
  },
  mounted() {
    this.listState();
    this.googleAutocomplete();
    if (this.$route?.query?.port) {
      this.port = true;
      this.canSubmit = true;
    }
    // this.locatorButtonPressed();
  },

  methods: {
    ...mapActions("auth", [
      "submitBusinessInfo",
      "login",
      "setMode",
      "portCustomer",
    ]),
    ...mapActions("notification", ["showAlert", "showToast"]),
    checkPhoneNumber(number, obj) {
      this.phoneIsValid = obj.valid;
      if (this.phoneIsValid) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
    sendPhoneOtp() {
      this.merchant.phoneOtp = "";
      this.loading = true;
      if (this.$route?.query?.port) {
        this.merchant.firstName = this.portUser.firstName;
        this.merchant.lastName = this.portUser.lastName;
        this.merchant.phoneNumber = null;
        this.portCustomer(this.merchant)
          .then(() => {
            this.verifyLoading = false;
            this.showOtpModal = false;
            this.showMessage = false;
          })
          .catch(() => {
            this.verifyLoading = false;
          });
      } else {
        PHONE_OTP(this.merchant.phoneNumber)
          .then((response) => {
            this.showOtpModal = true;
            this.loading = false;
            this.handleDelayOtpButton();
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    resendPhoneOtp() {
      this.resendLoading = true;
      PHONE_OTP(this.merchant.phoneNumber)
        .then((res) => {
          this.resendLoading = false;
          this.showToast({
            display: true,
            description: "A new OTP has been sent",
            icon: "success",
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.resendLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 30000);
    },
    updateBusinessInfo() {
      this.verifyLoading = true;
      // if (this.$route?.query?.port) {
      //   this.merchant.firstName = this.portUser.firstName;
      //   this.merchant.lastName = this.portUser.lastName;
      //   this.portCustomer(this.merchant)
      //     .then(() => {
      //       this.verifyLoading = false;
      //       this.showOtpModal = false;
      //       this.showMessage = false;
      //     })
      //     .catch(() => {
      //       this.verifyLoading = false;
      //     });
      // } else {
      this.submitBusinessInfo(this.merchant)
        .then(() => {
          this.verifyLoading = false;
          this.showMessage = false;
          this.showOtpModal = false;
        })
        .catch((error) => {
          this.verifyLoading = false;
          if (error.response.data.errorCode === "WRONG_OTP") {
            this.showOtpModal = true;
          } else {
            this.showOtpModal = false;
          }
        });
      // }
    },
    listState() {
      this.states = Location.map((location) => location.state);
    },

    // google maps integration starts here
    validateAddress() {
      if (this.isAddressValid) {
        this.errorOnAddress = false;
        document.getElementById("address").style.borderColor = "#000";
      } else {
        this.errorOnAddress = true;
        document.getElementById("address").style.borderColor = "#C70039";
      }
    },
    handleError() {
      if (this.isAddressValid) {
        this.merchant.businessAddress = "";
        this.merchant.businessState = "";
        this.merchant.businessLga = "";
      }
      document.getElementById("address").style.borderColor = "#000";
      this.errorOnAddress = false;
      this.isAddressValid = false;
    },
    googleAutocomplete() {
      document.getElementById("address").placeholder = "";

      let options = {
        // types: ["geocode"],
        componentRestrictions: { country: "ng" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        options
      );
      this.autocomplete.addListener("place_changed", () => {
        this.isAddressValid = true;
        this.validateAddress();
        let place = this.autocomplete.getPlace();
        let ac = place.address_components;
        const street = {
          no: "",
          name: "",
          neighborhood: "",
        };

        ac.forEach((address) => {
          if (address.types[0] === "street_number") {
            street.no = address.long_name;
          }
          if (address.types[0] === "route") {
            street.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            street.neighborhood = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            this.merchant.businessState = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            this.merchant.businessLga = address.long_name;
          }
        });

        // this.merchant.businessAddress =
        //   street.no + " " + street.name + ", " + street.neighborhood;
        this.merchant.businessAddress = place.name;
        if (
          this.merchant.businessState === "" ||
          this.merchant.businessLga === ""
        ) {
          this.isAddressValid = false;
          this.showAlert({
            display: true,
            description:
              "Please select a valid street address from the dropdown",
            type: "error",
          });
        }
      });
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    async getStreetAddressFrom(lat, long) {
      try {
        var { data } = await this.$axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            long +
            "&key=AIzaSyBjOiTISUUC_q6n1uoft7eXMOZyAd44EKQ"
        );
        if (data.error_message) {
          console.log(data.error_message);
        } else {
          this.merchant.businessAddress = data.results[0].formatted_address;
          this.isAddressValid = true;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    // google maps integration ends here

    loginUser() {
      this.dashboardLoading = true;
      this.login({
        username: this.ActiveUser.email,
        secret: this.ActiveUser.password,
        loginAs: "MERCHANT_USER",
      })
        .then(() => {
          this.dashboardLoading = false;
          this.setMode(false);
          // setTimeout(() => {
          //   this.$router.go();
          // }, 200);
        })
        .catch(() => {
          this.dashboardLoading = false;
        });
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
@import "../../../assets/styles/animation.css";
/* .register-container {
    min-height: calc(100vh - 9.5rem);
  } */
.register-container h3 {
  font-size: 35px;
}
.register-container p {
  line-height: 30.15px;
}
.container-register {
  max-width: 33.831rem;
}
.change {
  position: absolute;
  top: 0;
  right: 0;
}
.resend-otp {
  position: absolute;
  top: -0.2rem;
  right: 0;
}
@media (max-width: 768px) {
  .about-container {
    min-height: calc(100vh - 9.5rem);
  }
}
</style>
