import axios from "@/plugins/axios";
import cookies from "vue-cookies";

const getAuthorizationHeaders = (mode) => {
  return {
    Authorization: `Bearer ${mode ? cookies.get("token") : cookies.get("sandboxToken")}`,
  };
}

export const GET_ALL_ORDERS = (payload) => {
  const params = payload.params;
  const id = payload.id;
  const liveMode = payload.liveMode;
  let config = {
    headers: getAuthorizationHeaders(liveMode),
    params: params,
  };
  return axios.get(
    liveMode
      ? `/bnpl/purchase-order/merchant-outlet/${id}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase-order/merchant-outlet/${id}`,
    config
  );
};

export const GET_ALL_PURCHASES = (payload) => {
  const params = payload.params;
  const id = payload.id;
  const liveMode = payload.liveMode;
  let config = {
    headers: getAuthorizationHeaders(liveMode),
    params: params,
  };
  return axios.get(
    liveMode
      ? `/bnpl/purchase/merchant-outlet/${id}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/merchant-outlet/${id}`,
    config
  );
};

export const CREATE_ORDER = ({ data, liveMode }) => {
  return axios.post(
    liveMode
      ? `/bnpl/purchase-order`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase-order`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const VIEW_ONE_ORDER = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase-order/${id}/merchant-full-info`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase-order/${id}/merchant-full-info`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const VIEW_ONE_PURCHASE = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase/${id}/merchant-full-info`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/${id}/merchant-full-info`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};


export const CANCEL_ORDER = ({ id, data, liveMode }) => {
  return axios.put(
    liveMode
      ? `/bnpl/purchase-order/${id}/close-order`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase-order/${id}/close-order`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

// export const RECREATE_ORDER = (data) => {
//   return axios.post(`/bnpl/purchase-order`, data);
// };
