// import axios from "@/plugins/axios";
import { GET_ALL_SETTLEMENTS } from "@/utils/api/settlements";

export default {
  getAllSettlements({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_SETTLEMENTS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_SETTLEMENTS", data.data)
            : commit("GET_SETTLEMENTS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
