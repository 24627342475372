import axios from "@/plugins/axios";
import cookies from "vue-cookies";

const getAuthorizationHeaders = (mode) => {
  return {
    Authorization: `Bearer ${mode ? cookies.get("token") : cookies.get("sandboxToken")}`,
  };
}

// ACCOUNT ENDPOINTS
export const GET_ALL_BANKS = () => {
  return axios.get(`/bnpl/lookup/all-banks`);
};

export const GET_USER_CASHOUT_ACCOUNT = (id) => {
  return axios.get(`/bnpl/cashout-bank-account`, {
    params: {
      ownerId: `${id}`
    }
  });
};

export const GET_BANK_ACCOUNT_NAME = (data) => {
  return axios.post(`/bnpl/lookup/account-number`, data);
};

export const SAVE_ACCOUNT_INFO = (id, data) => {
  return axios.put(`/bnpl/merchant-outlet/${id}/update-cashout-account`, data);
};

export const VERIFY_OTP = (id, data) => {
  return axios.put(`/bnpl/merchant-outlet/${id}/update-cashout-account`, data);
};

export const GET_ACCOUNT_DETAILS = (id) => {
  return axios.get(`/bnpl/merchant-outlet/${id}`);
};

// API WEBHOOK ENDPOINTS
export const GET_WEBHOOK_DETAILS = ({ liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/merchant/programmatic-access`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant/programmatic-access`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const ENABLE_WEBHOOK_ACCESS = ({ data, liveMode }) => {
  return axios.put(
    liveMode
      ? `/bnpl/merchant/enable-programmatic-access`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant/enable-programmatic-access`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const UPDATE_WEBHOOK_ACCESS = ({ data, liveMode }) => {
  return axios.put(
    liveMode
      ? `/bnpl/merchant/update-programmatic-access`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant/update-programmatic-access`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const TOGGLE_PROGRAMMATIC_ACCESS = (data) => {
  return axios.put(`/bnpl/merchant/toggle-dashboard-mode`, data);
};

// BUSINESS DETAILS ENDPOINTS

export const GET_BUSINESS_INFO = () => {
  return axios.get(`/bnpl/merchant/login-user`);
};

export const GET_BUSINESS_CATEGORIES = () => {
  return axios.get(`/bnpl/merchant/business-categories`);
};

export const UPDATE_BUSINESS_INFO = (data) => {
  return axios.put(`/bnpl/merchant/update-business-info`, data);
};
