import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import Register from "../views/Register.vue";
// import Otp from "../views/Otp";
import Login from "../views/Login";
import About from "../views/About";
// import ForgotPassword from "../views/ForgotPassword";
// import Join from "../views/Join";
// import Orders from "@/views/Order";
// import Settlements from "@/views/Settlements";
// import Activate from "@/views/Activate";
// import Settings from "@/views/Settings";
// import CreatePassword from "@/views/CreatePassword";
import store from "../store";
import cookies from "vue-cookies";
// import About from "@/views/About.vue";
// import Merchant from "@/views/Merchant";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Merchant",
  //   component: Merchant,
  //   meta: { layout: "bare", guest: true },
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/otp",
    name: "Otp",
    component: () => import("@/views/Otp"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: () => import("@/views/ForgotPassword"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/create-password",
    name: "Create Password",
    component: () => import("@/views/CreatePassword"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/port",
    name: "Port",
    components: () => import("@/views/Port"),
    meta: { layout: "auth", guest: true },
  },
  {
    path: "/payment-links",
    name: "Orders",
    component: () => import("@/views/Order"),
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/purchases",
    name: "Purchases",
    component: () => import("@/views/Purchases"),
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/settlements",
    name: "Settlements",
    component: () => import("@/views/Settlements"),
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/activate-store",
    name: "Activate",
    component: () => import("@/views/Activate"),
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings"),
    meta: { layout: "default", requiresAuth: true },
  },
  {
    path: "*",
    redirect: "/",
    name: "Not Found",
    component: Home,
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.query.ref) {
    cookies.set("referralCode", to.query.ref);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next();
      return;
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next("/");
      return;
    } else {
      next();
      return;
    }
  }
});

export default router;
