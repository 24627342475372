<template>
  <transition :name="currentAnimation">
    <!-- <AnimateHeight> -->
    <div
      class="fixed modal-container scrollbar"
      v-if="display"
      @click="clickTarget"
    >
      <transition :name="currentAnimation">
        <div class="modal-body bg-white p-6 md:p-10" v-if="display">
          <div class="flex justify-between items-center">
            <!-- <div> -->
            <h3 class="text-black text-lg md:text-xl font-semibold">
              {{ title }}
            </h3>
            <!-- </div> -->
            <img
              class="h-3 w-3 cursor-pointer"
              src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
              alt="cancel"
              v-if="close"
              @click="closeDisplay"
            />
          </div>
          <AnimateHeight>
            <div>
              <slot />
            </div>
          </AnimateHeight>
        </div>
      </transition>
    </div>
    <!-- </AnimateHeight> -->
  </transition>
</template>
<script>
import AnimateHeight from "../AnimateHeight";
export default {
  components: {
    AnimateHeight,
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    matchWidth: "slideUp",
  }),
  computed: {
    currentAnimation() {
      if (window.matchMedia("(max-width: 1024px)").matches) {
        return "slideUp";
      } else {
        return "fade";
      }
    },
  },
  methods: {
    closeDisplay() {
      this.$emit("close");
    },
    clickTarget(e) {
      if (this.close) {
        if (e.target.className.includes("modal-container")) {
          this.closeDisplay();
        }
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/styles/animation.css";
.modal-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(61, 61, 61, 0.8);
  z-index: 10;
}
.modal-body {
  position: fixed;
  bottom: 10px;
  border-radius: 20px;
  max-height: 80vh;
  overflow-y: auto;
  left: 2.5%;
  right: 2.5%;
  margin: auto;
  width: 95%;
  z-index: 8;
  box-shadow: 0px -10px 50px rgba(175, 177, 225, 0.15);
}
.tent {
  max-height: 80vh;
  overscroll-behavior: contain;
  /* overflow-y: auto; */
  /* padding: 2rem; */
}

.modal-content::-webkit-scrollbar {
  width: 5px !important;
}
.modalcontent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px;
}
.modal-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

@media (min-width: 1024px) {
  .modal-container {
    display: grid;
    place-items: center;
  }

  .modal-body {
    position: initial;
    width: 654px;
    border-radius: 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.45, 0.25, 0.6, 0.95);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(0%, -10px);
  opacity: 0;
}
</style>
