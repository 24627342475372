<template>
  <div>
    <h6 class="text-xl font-semibold text-black">Provide your bank details</h6>
    <p class="md:text-lg text-black">
      We need your bank account details so you can withdraw payments seamlessly.
    </p>
    <div class="mt-6">
      <Select
        class="mt-6"
        width="w-full"
        label="prettyName"
        @input="bankChanged"
        placeholder="Select bank"
        :options="bankList"
        :reduce="(bank) => bank.id"
        v-model="businessInfo.cashoutAccountBankId"
        errorText="Please select a bank"
        :validation="rules.bank"
        @valid="valid.bank = $event"
      />

      <Input
        placeholder="What’s your account number?"
        width="w-full"
        type="number"
        class="mt-8"
        v-model="businessInfo.cashoutAccountNumber"
        errorText="Please enter a valid account number"
        :validation="rules.number"
        @valid="valid.number = $event"
      />

      <Input
        placeholder="Account name"
        class="mt-8"
        width="w-full"
        v-model="businessInfo.cashoutAccountName"
        disabled="disabled"
        errorText="Must be a valid account name"
        :validation="rules.name"
        @valid="valid.name = $event"
      />
      <Button
        text="Complete activation"
        width="w-full"
        class="mt-16"
        p="py-3.5"
        :loading="loading"
        @click="saveInfo()"
        :disabled="disableButton"
      />

      <Modal :display="showUpdateSuccess" @close="showUpdateSuccess = false">
        <h5 class="text-black text-lg font-semibold">
          We have sent a code to you.
        </h5>

        <p class="mt-4 text-sm">
          Just to be sure you own the bank account provided.
        </p>
        <Input
          class="mt-8"
          placeholder="Enter OTP"
          width="w-full"
          v-model="businessInfo.otp"
          type="number"
          errorText="OTP must be 6 characters"
          :validation="otp.otpValid"
          @valid="otpValid = $event"
        />

        <Button
          text="Continue"
          width="w-full"
          class="my-8"
          @click="verifyOtp"
          :loading="verifyLoading"
          :disabled="disableOtpButton"
        />
      </Modal>
    </div>
  </div>
</template>
<script>
import {
  GET_ALL_BANKS,
  GET_BANK_ACCOUNT_NAME,
  GET_ACCOUNT_DETAILS,
  SAVE_ACCOUNT_INFO,
  GET_USER_CASHOUT_ACCOUNT,
} from "@/utils/api/settings.js";
import { mapState, mapActions } from "vuex";
import Input from "@/UI/Input";
import Select from "@/UI/Select";
import Button from "@/UI/Button";
import Modal from "@/UI/Modal";

export default {
  components: {
    Input,
    Button,
    Select,
    Modal,
  },
  data: () => ({
    bankList: [],
    businessInfo: {
      cashoutAccountNumber: "",
      cashoutAccountBankId: "",
      cashoutAccountName: "",
    },
    loading: false,
    showUpdateSuccess: false,
    verifyLoading: false,
    valid: {
      number: false,
      bank: false,
      name: false,
    },
    otpValid: false,
  }),
  computed: {
    ...mapState({
      merchantOutletId: (state) => state?.auth?.user?.merchantOutlet.id,
    }),
    getAccountName() {
      if (
        this.businessInfo?.cashoutAccountNumber?.length === 10 &&
        this.businessInfo?.cashoutAccountBankId !== null
      ) {
        return true;
      } else return false;
    },
    rules() {
      return {
        number: this.businessInfo?.cashoutAccountNumber?.length === 10,
        bank: this.businessInfo?.cashoutAccountBankId?.length !== 0,
        name:
          this.businessInfo?.cashoutAccountName !== null &&
          this.businessInfo?.cashoutAccountName !== "",
      };
    },
    otp() {
      return {
        otpValid: this.businessInfo?.otp?.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otp).includes(false);
    },
  },
  watch: {
    getAccountName() {
      if (this.getAccountName) {
        this.getBankDetails();
      }
    },
  },
  mounted() {
    this.getAccountDetails();
    this.getBanks();
  },
  methods: {
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("auth", ["updateAccountInfo"]),
    ...mapActions("loading", ["setLoading"]),
    getBanks() {
      GET_ALL_BANKS()
        .then((res) => {
          this.bankList = res.data.data;
        })
        .catch(() => {});
    },
    submit() {
      this.$emit("next");
    },
    bankChanged() {
      this.businessInfo.cashoutAccountNumber = "";
      this.businessInfo.cashoutAccountName = "";
    },
    getAccountDetails() {
      this.setLoading(true);
      GET_USER_CASHOUT_ACCOUNT(this.merchantOutletId)
        .then((res) => {
          if (res.data.data) {
            this.setLoading(false);
            const data = res.data.data;
            this.businessInfo.cashoutAccountBankId = data?.bank?.id;
            this.businessInfo.cashoutAccountNumber = data?.number;
            this.businessInfo.cashoutAccountName = data?.name;
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    getBankDetails() {
      this.setLoading(true);
      GET_BANK_ACCOUNT_NAME({
        accountNumber: this.businessInfo.cashoutAccountNumber,
        bankId: this.businessInfo.cashoutAccountBankId,
      })
        .then((res) => {
          this.setLoading(false);
          this.businessInfo.cashoutAccountName = res.data.data.accountName;
        })
        .catch(() => {
          this.setLoading(false);
          this.businessInfo.cashoutAccountName = "";
        });
    },
    verifyOtp() {
      this.verifyLoading = true;
      SAVE_ACCOUNT_INFO(this.merchantOutletId, {
        accountNumber: this.businessInfo.cashoutAccountNumber,
        accountName: this.businessInfo.cashoutAccountName,
        bankId: this.businessInfo.cashoutAccountBankId,
        otp: this.businessInfo.otp,
      })
        .then(() => {
          this.showUpdateSuccess = false;
          this.verifyLoading = false;
          setTimeout(() => {
            this.$emit("next");
          }, 500);
          this.updateAccountInfo(response.data.data.merchantOutlet);
        })
        .catch(() => {
          this.verifyLoading = false;
        });
    },
    saveInfo() {
      this.loading = true;

      SAVE_ACCOUNT_INFO(this.merchantOutletId, {
        accountNumber: this.businessInfo.cashoutAccountNumber,
        accountName: this.businessInfo.cashoutAccountName,
        bankId: this.businessInfo.cashoutAccountBankId,
      })
        .then((res) => {
          this.loading = false;
          if (res.data.data.sendOtpResponse) {
            this.showUpdateSuccess = true;
          } else {
            this.$emit("next");
            this.updateAccountInfo(response.data.data.merchantOutlet);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped></style>
