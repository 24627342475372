<template>
  <div class="container-body">
    <Sidebar class="hidden md:block" />
    <MobileNav class="md:hidden" />
    <div class="main">
      <Navbar />
      <div class="body-content pb-12 mx-3 md:mx-0 md:pl-6  md:pr-8 ">
        <div class="container h-full">
          <slot />
        </div>
      </div>
      <!-- <MobileNav class="md:hidden" /> -->
    </div>
  </div>
</template>
<script>
import Sidebar from "./Sidebar.vue";
import Navbar from "./Navbar.vue";
import MobileNav from "./MobileNav.vue";
export default {
  components: {
    Sidebar,
    Navbar,
    MobileNav,
  },
};
</script>
<style scoped>
.container-body {
  display: grid;
  grid-template-columns: 265px 1fr;
  min-height: 100%;
  /* min-height: -webkit-fill-available; */
  width: 100%;
  background-color: #F3F3F6;
}

@media (max-width: 768px) {
  .container-body {
    display: block;
    /* grid-template-rows: 6rem 1fr 4rem; */
  }
}
.main {
  min-height: 100vh;
  /* min-height: -webkit-fill-available; */
}
.body-content {
  height: calc(100vh - 6rem);
  overflow-y: auto;
  /* overflow-x: hidden; */
}
.body-content::-webkit-scrollbar {
  width: 5px !important;
}
.body-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3;
  border-radius: 10px;
}
.body-content::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .main {
    display: grid;
    grid-template-rows: 4.5rem 1fr 2rem;
  }
  .bottom-nav {
    background-color: white;
  }
  .body-content::-webkit-scrollbar {
    width: 0 !important;
  }
  .body-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d3d3d3;
    border-radius: 10px;
  }
  .body-content::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 10px;
  }
}
</style>
