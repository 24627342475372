<template>
  <div>
    <h6 class="text-xl font-semibold text-black">
      Tell us about your business
    </h6>
    <p class="md:text-lg text-black">
      Just a little more details to help us setup your store for checkout and
      make it look sweet.
    </p>
    <div class="mt-8">
      <Upload
        class="mt-14"
        :text="uploadText"
        :fileType="['png', 'jpeg', 'jpg']"
        @upload="handleUpload"
      />
      <small class="py-1"
        >You can only upload JPG, PNG and JPEG file type that is less than
        5MB</small
      >
      <Input
        width="w-full"
        placeholder="What's the website of the business?"
        class="mt-11"
        v-model="businessInfo.businessWebsite"
        errorText="Please enter a valid website url"
        :validation="rules.website"
        @valid="valid.website"
      />
      <Select
        class="mt-11"
        width="w-full"
        label="name"
        placeholder="Select your business category"
        :options="businessCategories"
        :reduce="(category) => category.key"
        v-model="businessInfo.businessCategory"
        errorText="Business category is required"
        :validation="rules.category"
        @valid="valid.category"
      />

      <small class="py-1"
        >It’s possible your category isn’t listed here. If that’s the case,
        select others.</small
      >

      <Select
        class="mt-11"
        width="w-full"
        label="name"
        placeholder="Select your average monthly sales range"
        :options="monthlySales"
        :reduce="(sales) => sales.key"
        v-model="businessInfo.averageMonthlySalesVolume"
        errorText="Average monthly sales is required"
        :validation="rules.sales"
        @valid="valid.sales"
      />

      <Button
        text="Continue to next stage"
        width="w-full"
        class="mt-12"
        p="py-3.5"
        :loading="loading"
        :disabled="disableButton"
        @click="submitBusinessInfo()"
      />
    </div>
  </div>
</template>
<script>
import {
  GET_BUSINESS_CATEGORIES,
  GET_BUSINESS_INFO,
} from "@/utils/api/settings.js";
import {
  GET_MONTHLY_SALES,
  SUBMIT_BUSINESS_INFO,
} from "@/utils/api/activate.js";
import { mapActions } from "vuex";
import Input from "@/UI/Input";
import Button from "@/UI/Button";
import Upload from "@/UI/Upload";
import Select from "@/UI/Select";
export default {
  components: {
    Input,
    Button,
    Upload,
    Select,
  },
  data: () => ({
    businessCategories: [],
    monthlySales: [],
    businessInfo: {
      logoId: "",
      businessWebsite: "",
      businessCategory: "",
      averageMonthlySalesVolume: "",
    },
    loading: false,
    valid: {
      website: false,
      category: false,
      sales: false,
    },
    uploadText: "Business logo",
  }),
  computed: {
    rules() {
      return {
        website:
          /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm.test(
            this.businessInfo.businessWebsite
          ) || this.businessInfo.businessWebsite.length === 0,
        logoId: this.businessInfo.logoId.length !== 0,
        category: this.businessInfo.businessCategory.length !== 0,
        sales: this.businessInfo.averageMonthlySalesVolume.length !== 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.getBusinessCategories();
    this.getMonthlySales();
    this.getBusinessInfo();
  },
  methods: {
    ...mapActions("auth", ["activateStore", "getLoggedInMerchant"]),
    ...mapActions("loading", ["setLoading"]),
    getBusinessCategories() {
      GET_BUSINESS_CATEGORIES()
        .then((res) => {
          this.businessCategories = res.data.data;
        })
        .catch(() => {});
    },
    getBusinessInfo() {
      this.setLoading(true);
      GET_BUSINESS_INFO()
        .then((response) => {
          this.setLoading(false);
          const data = response.data.data;
          if (data.logoId) {
            this.businessInfo.logoId = data.logoId;
            this.uploadText = "Logo uploaded";
          }
          if (data.businessWebsite) {
            this.businessInfo.businessWebsite = data.businessWebsite;
          }
          if (data.businessCategory) {
            this.businessInfo.businessCategory = data.businessCategory;
          }
          if (data.averageMonthlySalesValue) {
            this.businessInfo.averageMonthlySalesVolume =
              data.averageMonthlySalesValue;
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    getMonthlySales() {
      GET_MONTHLY_SALES()
        .then((res) => {
          this.monthlySales = res.data.data;
        })
        .catch(() => {});
    },
    handleUpload(e) {
      this.businessInfo.logoId = e.id;
    },
    submitBusinessInfo() {
      this.loading = true;
      if (this.businessInfo.businessWebsite === "") {
        this.businessInfo.businessWebsite = null;
      } else {
        const website = this.businessInfo.businessWebsite.toLowerCase();
        if (
          website.indexOf("http://") == 0 ||
          website.indexOf("https://") == 0
        ) {
          this.businessInfo.businessWebsite = website;
        } else {
          this.businessInfo.businessWebsite = "http://" + website;
        }
      }
      SUBMIT_BUSINESS_INFO(this.businessInfo)
        .then(() => {
          this.loading = false;
          this.$emit("next");
          this.getLoggedInMerchant().then(() => {
            this.activateStore(true);
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
