import axios from "axios";
// import cookies from "vue-cookies";
import store from "../store";

// const token = cookies.get("token");

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // headers: {
  //   Authorization: `Bearer ${token}`,
  // },
});

// axiosInstance.interceptors.request.use((config) => {
//   if (token) {
//     config.headers["Authorization"] = `Bearer ${token}`;
//     return config;
//   }
//   return config;
// });

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error?.response?.status === 401) {
      store.dispatch("auth/logout");
    } else if (error?.response?.status === 404) {
      console.log(error.response.data.message);
    } else if (error?.response?.data?.data?.length > 0) {
      store.dispatch("notification/showAlert", {
        display: true,
        type: "cancel",
        description: error?.response?.data?.data[0],
      });
    } else if (error?.response?.data?.message) {
      store.dispatch("notification/showAlert", {
        display: true,
        type: "cancel",
        description: error.response.data.message,
      });
    } else {
      store.dispatch("notification/showAlert", {
        display: true,
        type: "cancel",
        description:
          "We are sorry this happened. Please check your internet connection and try again",
        title: "An error occured",
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
