<template>
  <div class="about">
    <About />
  </div>
</template>
<script>
import About from "@/components/Auth/About";
export default {
  components: {
    About,
  },
};
</script>
