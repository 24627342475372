<template>
  <hr class="bg-lightPurple   border-lightPurple" :class="[margin]" />
</template>
<script>
  export default {
    props: {
      margin: {
        type: String,
        default: "my-2",
      },
    },
  };
</script>
