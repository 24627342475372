// import axios from "@/plugins/axios";
import {
  GET_WEBHOOK_DETAILS
} from "@/utils/api/settings.js";

export default {
  getWebhookInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_WEBHOOK_DETAILS(payload)
        .then((res) => {
          commit("UPDATE_WEBHOOK_INFO", res.data.data);
          commit("SHOW_WEBHOOK_FORM", true);
          commit("SHOW_ENABLE_WEBHOOK", false)
          resolve(res);
        })
        .catch((err) => {
          if (err.response.data.errorCode === "NO_PROGRAMMATIC_ACCESS") {
            commit("SHOW_ENABLE_WEBHOOK", true);
            commit("SHOW_WEBHOOK_FORM", false);
          }
          reject(err);
        });
    });
  },
};
