<template>
  <div class="barcode">
    <card class="mt-4 md:mt-0">
      <div
        class="flex justify-center md:items-center h-full text-center w-full"
      >
        <div>
          <transition name="fade" mode="out-in">
            <img
              v-if="qrCode"
              class="mx-auto p-4 h-40 w-40"
              :src="qrCode"
              alt=""
            />
            <div v-else class="w-full flex justify-center">
              <Skeleton height="7rem" width="7rem" class="p-4 mx-auto" />
            </div>
          </transition>

          <h2 class="font-semibold md:text-lg">Payment link for your store</h2>
          <p style="color: #7e7694" class="pb-6 mx-4">
            {{ link }}
          </p>
          <Button
            text="Copy link"
            p="p-2"
            width="w-24"
            fontSize="text-xs"
            background="bg-white"
            border="border"
            borderColor="border-brandPurple"
            color="text-brandPurple"
            class="mx-auto mb-4"
            @click="copy(link)"
          />
        </div>
      </div>
    </card>
    <card class="h-full" p="py-0 px-4">
      <div class="h-full relative">
        <div class="flex justify-center md:items-center h-full py-10">
          <div class="md:text-center">
            <h2 class="font-semibold md:text-lg">Sales Leaderboard</h2>
            <p class="text-center">COMING SOON</p>
          </div>
        </div>
        <div class="absolute bottom-0 right-0">
          <img
            src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1630154030/merchant/Group_2_h7vnjn.svg"
            alt="Badge"
            class="h-24 w-20"
          />
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Button from "@/UI/Button";
import { mapState } from "vuex";
import copy from "@/mixins/copy";
import { GET_QR_CODE, GET_MERCHANT_OUTLET } from "@/utils/api/overview";
export default {
  mixins: [copy],
  components: {
    Button
  },
  data: () => ({
    link: "",
    qrCode: ""
  }),
  computed: {
    ...mapState({
      merchant: state => state?.auth?.user,
      liveMode: state => state?.auth?.liveMode
    })
  },
  mounted() {
    this.getQrCode();
    this.getMerchantOutlet();
  },
  watch: {
    liveMode() {
      this.getQrCode();
      this.getMerchantOutlet();
    }
  },
  methods: {
    getQrCode() {
      GET_QR_CODE(this.merchant.merchantOutlet.id)
        .then(res => {
          const urlCreator = window.URL || window.webkitURL;
          this.qrCode = urlCreator.createObjectURL(res.data);
        })
        .catch(() => {});
    },
    getMerchantOutlet() {
      GET_MERCHANT_OUTLET({
        id: this.merchant.merchantOutlet.id,
        liveMode: this.liveMode
      })
        .then(res => {
          this.link = res.data.data.paymentLink;
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.barcode {
  display: grid;
  grid-template-rows: 70% 1fr;
  grid-gap: 16px;
}
</style>
