<template>
  <Home />
</template>

<script>
  // @ is an alias to /src
  import Home from "@/components/Home";

  export default {
    // name: "Home",
    components: {
      Home,
    },
  };
</script>
