// import Vue from "vue";
import store from "../store";

// Vue.mixin({
//   methods: {
//     copy(value) {
//       navigator.clipboard.writeText(value);
//       store.dispatch("notification/showToast", {
//         display: true,
//         icon: "success",
//         description: "Copied",
//       });
//     },
//   },
// });

export default {
  methods: {
    copy(value) {
      navigator.clipboard.writeText(value);
      store.dispatch("notification/showToast", {
        display: true,
        icon: "success",
        description: "Copied to clipboard",
      });
    },
  },
};
