<template>
  <div class="min-h-screen layout-container w-full grid grid-cols-2">
    <div
      class="h-full  intro pt-0 md:pt-8 px-24 first-section"
      :style="{ 'background-image': `url(${background})` }"
    >
      <img
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1623797871/zilla_plain_hvovwf.svg"
        alt=""
        class="h-10 logo lg:mt-4"
      />
      <!-- <div class="intro_content grid place-items-center">
        <div>
          <h1 class=" text-white">
            You are a few steps from living your best life.
          </h1>

          <p class="text-white mt-16">
            Zilla is a new consumer payment service platform designed to provide
            point-of-sale financing for consumers across Africa.
          </p>
        </div>
      </div> -->
    </div>
    <div class="overflow-y-auto second-section relative">
      <p class="lg:hidden text-white font-semibold my-2 ml-4 py-1"></p>

      <div class="min-h-full navigation">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      nav: String,
    },
    data: () => ({
      // background: "",
    }),
    computed: {
      background() {
        if (this.$route.path === "/login") {
          return "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1628137054/merchant/image_32_yxbf7g.png";
        }
        if (this.$route.path === "/register") {
          return "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1628950673/merchant/hero_banner3_qwkrb4.jpg";
        }
        if (this.$route.path === "/otp") {
          return "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1628950424/merchant/herobanner_2_moigkt.jpg";
        }
        if (
          this.$route.path === "/forgot-password" ||
          this.$route.path === "/create-password"
        ) {
          return "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1628144181/merchant/image_47_lixalp.png";
        } else {
          return "https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1628950424/merchant/herobanner_2_moigkt.jpg";
        }
      },
    },
    // mounted() {
    //   console.log(this.$route);
    // },
  };
</script>
<style scoped>
  .intro {
    background-position: center center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    /* background: white; */
    background-color: #f6f7ff;
    /* background-image: url("~@/assets/images/hero.svg"), */
    /* linear-gradient(151.79deg, #c6003b -6.33%, #6a30c3 53.84%, #63dfdf 99.9%); */
  }
  .intro_content {
    min-height: calc(100vh - 22rem);
    /* width: 70%; */
    margin: auto;
  }

  .intro_content h1 {
    font-size: 64px;
    font-family: "Gilroy", Helvetica;
  }
  .intro_content p {
    font-size: 18px;
    font-family: "Gilroy", Helvetica;
  }

  @media (max-width: 768px) {
    .intro {
      background: initial;
    }
    .layout-container {
      grid-template-columns: 100%;
      background-image: url("~@/assets/images/hero.svg"),
        linear-gradient(
          141.79deg,
          #c6003b -40.33%,
          #4d2ba1 13.84%,
          #63dfdf 69.9%
        );
    }
    .layout-container .first-section {
      height: 8rem;
      display: grid;
      place-items: center;
      background-image: none !important;
    }
    .layout-container .second-section {
      height: calc(100vh - 8rem);
      /* overflow-y: auto; */
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px;
      background: linear-gradient(
        143.34deg,
        rgba(255, 255, 255, 0.3) 11.25%,
        rgba(255, 255, 255, 0) 91.56%
      );
      z-index: 10 !important;
    }
    .navigation {
      background: black;
      min-height: calc(100vh - 9.5rem);
      left: 0;
      z-index: -1;
      width: 100%;
      background: white;
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px;

      /* margin-top: 1rem; */

      /* margin-top: -2rem; */
      /* top: 6rem; */
      /* transform: translateY(-2rem); */
      /* position: absolute; */
    }
    .intro_content {
      display: none;
    }
  }
  /* .logo {
    filter: drop-shadow(0.25rem 0.25rem 0.3rem rgba(0, 0, 0, 0.5));
  } */
</style>
