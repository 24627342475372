import axios from "@/plugins/axios";
import cookies from "vue-cookies";

const getAuthorizationHeaders = (mode) => {
  return {
    Authorization: `Bearer ${mode ? cookies.get("token") : cookies.get("sandboxToken")}`,
  };
}

export const GET_ALL_PURCHASES = (payload) => {
  const params = payload.params;
  const id = payload.id;
  const liveMode = payload.liveMode;
  let config = {
    headers: getAuthorizationHeaders(liveMode),
    params: params,
  };
  return axios.get(
    liveMode
      ? `/bnpl/purchase/merchant-outlet/${id}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/merchant-outlet/${id}`,
    config
  );
};

export const VIEW_ONE_PURCHASE = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase/${id}/merchant-full-info`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/${id}/merchant-full-info`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const REFUND_PURCHASE = ({ data, liveMode }) => {
  return axios.put(
    liveMode
      ? `/bnpl/purchase/refund`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/refund`,
    data,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const GET_REFUND_REASONS = ({ liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase/refund/reason`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/refund/reason`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};

export const GET_REFUND_BREAKDOWN = ({ id, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase/${id}/refund/breakdown`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/${id}/refund/breakdown`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};





