// import axios from "@/plugins/axios";
import { GET_ALL_ORDERS, GET_ALL_PURCHASES } from "@/utils/api/order";

export default {
  showOrderModal({ commit }, payload) {
    commit("SHOW_ORDER_MODAL", payload);
  },
  showOrderSuccessModal({ commit }, payload) {
    commit("SHOW_ORDER_SUCCESS_MODAL", payload);
  },
  getAllOrders({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_ORDERS(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_ORDERS", data.data)
            : commit("GET_ORDERS", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getAllPurchases({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_ALL_PURCHASES(payload)
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_PURCHASES", data.data)
            : commit("GET_PURCHASES", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
