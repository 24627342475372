<template>
  <div class="text-center">
    <div>
      <img
        class="h-32 w-32 mx-auto"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
        alt="success"
      />
      <h5
        class="mt-12 text-black  text-center text-xl md:text-2xl font-semibold"
      >
        Your details have been submitted successfully
      </h5>
      <p class="text-center mt-12">
        A member of our team will reach out to you and your merchant account
        will be verified within 24 working hours.
      </p>
      <Button
        text="Go to dashboard"
        width="w-full"
        class="mt-16"
        p="py-3.5"
        @click="activationDone()"
        :loading="loading"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Button from "@/UI/Button";
export default {
  components: {
    Button
  },
  data: () => ({
    loading: false
  }),
  methods: {
    ...mapActions("auth", ["getLoggedInMerchant"]),
    ...mapActions("notification", ["showAlert"]),
    activationDone() {
      this.loading = true;
      this.getLoggedInMerchant()
        .then(() => {
          this.$emit("next");
          this.loading = false;
          this.$router.push("/");
          // setTimeout(() => {
          //   this.showAlert({
          //     display: true,
          //     description:
          //       "Your account is currently on sandbox/test mode pending approval.",
          //     type: "error"
          //   });
          // }, 1000);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped>
/* .container-body {
  min-height: calc(100vh - 10rem);
  display: grid;
  place-items: center;
} */
</style>
