import axios from "@/plugins/axios";
import cookies from "vue-cookies";

const getAuthorizationHeaders = (mode) => {
  return {
    Authorization: `Bearer ${mode ? cookies.get("token") : cookies.get("sandboxToken")}`,
  };
}

export const GET_ALL_SETTLEMENTS = (payload) => {
  const params = payload.params;
  const id = payload.id;
  const liveMode = payload.liveMode;
  let config = {
    headers: getAuthorizationHeaders(liveMode),
    params: params,
  };
  return axios.get(
    liveMode
      ? `/bnpl/merchant-settlement/merchant-outlet/${id}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/merchant-settlement/merchant-outlet/${id}`,
    config
  );
};

export const GET_SETTLEMENT_TRANSACTIONS = ({ id, settlementId, liveMode }) => {
  return axios.get(
    liveMode
      ? `/bnpl/purchase/merchant-outlet/${id}?merchantSettlementId=${settlementId}`
      : `${process.env.VUE_APP_SANDBOX_BASE_URL}/bnpl/purchase/merchant-outlet/${id}?merchantSettlementId=${settlementId}`,
    {
      headers: getAuthorizationHeaders(liveMode)
    }
  );
};
