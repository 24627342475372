import {
  CASHOUT_HISTORY,
  GET_WALLET,
  GET_TOPUP_ACCOUNT
} from "@/utils/api/overview";

export default {
  getCashoutHistory({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CASHOUT_HISTORY({
        payload: payload.params,
        liveMode: payload.liveMode
      })
        .then(({ data }) => {
          payload.type === "paginate"
            ? commit("ADD_CASHOUT_HISTORY", data.data)
            : commit("GET_CASHOUT_HISTORY", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  showPhoneModal({ commit }, payload) {
    commit("SHOW_PHONE_MODAL", payload);
  },
  getMerchantWallet({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_WALLET({
        id: payload.id,
        liveMode: payload.liveMode
      })
        .then(({ data }) => {
          commit("UPDATE_MERCHANT_WALLET", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getMerchantTopupAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      GET_TOPUP_ACCOUNT({
        liveMode: payload.liveMode
      })
        .then(({ data }) => {
          commit("UPDATE_TOPUP_ACCOUNT", data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
