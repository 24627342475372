export default {
  GET_CASHOUT_HISTORY(state, payload) {
    state.cashoutHistory = payload;
  },
  ADD_CASHOUT_HISTORY(state, payload) {
    state.cashoutHistory.push(...payload);
  },
  SHOW_PHONE_MODAL(state, payload) {
    state.showPhoneModal = payload;
  },
  UPDATE_MERCHANT_WALLET(state, payload) {
    state.wallet = payload;
  },
  UPDATE_TOPUP_ACCOUNT(state, payload) {
    state.topupAccount = payload;
  }
};
