<template>
  <div>
    <!-- create order form modal -->
    <Modal :display="showPhone" @close="showPhoneModal(false)" :close="false">
      <div class="form">
        <h4 class="text-xl font-bold text-black mb-4 text-center">
          We might likely contact you!
        </h4>
        <p class="text-center">
          We might need to contact you sometimes to clarify some details. Kindly
          give us your phone number.
        </p>
        <vue-tel-input
          v-model="merchant.phone"
          :enabled-country-code="false"
          :input-options="{ showDialCode: false, tabindex: 0 }"
          :required="true"
          :valid-characters-only="true"
          :mode="'international'"
          @input="checkPhoneNumber"
          defaultCountry="NG"
          class="mt-10"
          wrapper-classes="border-0 focus:outline-none pl-12 text-brand_dark_blue"
          :inputOptions="options"
        ></vue-tel-input>
        <p
          :style="{
            visibility: phoneIsValid === false ? 'visible' : 'hidden',
          }"
          class="text-brandRed text-xs py-1"
        >
          Invalid phone number for the country code selected
        </p>

        <Button
          text="Submit"
          class="mt-6"
          :shadow="true"
          width="w-full"
          :loading="loading"
          :disabled="disableButton"
          @click="initiatePhoneVerification"
        />
      </div>
    </Modal>
    <!-- success modal -->
    <Modal :display="showOtpModal" @close="closeOtpModal()" title="Enter OTP">
      <h5 class="text-dark text-lgmt-3">
        We sent an SMS and a whatsapp message to {{ merchant.phone }}.
      </h5>

      <div class="relative">
        <Input
          class="mt-8"
          placeholder="Enter OTP"
          width="w-full"
          v-model="merchant.otp"
          type="number"
          errorText="OTP must be 6 characters"
          :validation="otprules.otpValid"
          @valid="otpValid = $event"
          hideErrorIcon
        />
        <SecondaryButton
          v-if="showResendButton"
          class="resend-otp z-10"
          radius="rounded-full"
          fontSize="text-xs"
          background="bg-lightPurple"
          color="brandPurple"
          text="Resend OTP"
          :loading="resendLoading"
          width="w-28"
          @click="resendPhoneOtp"
        />
      </div>

      <Button
        text="Verify phone number"
        width="w-full"
        class="my-8"
        @click="verifyPhone"
        :loading="verifyLoading"
        :disabled="disableOtpButton"
      />
    </Modal>
  </div>
</template>
<script>
import { INITIATE_PHONE_OTP, VERIFY_PHONE } from "@/utils/api/auth.js";
import { mapActions, mapState } from "vuex";
import Input from "@/UI/Input";
import CurrencyInput from "@/UI/CurrencyInput";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/Button";
import Modal from "@/UI/Modal";
import { VueTelInput } from "vue-tel-input";
export default {
  components: {
    Input,
    Button,
    SecondaryButton,
    Modal,
    CurrencyInput,
    VueTelInput,
  },
  data() {
    return {
      merchant: {
        phone: "",
        otp: "",
      },
      loading: false,
      phoneIsValid: true,
      canSubmit: false,
      options: { placeholder: "Phone number" },
      showOtpModal: false,
      otpValid: false,
      verifyLoading: false,
      showResendButton: false,
      resendLoading: false,
    };
  },
  computed: {
    ...mapState({
      showPhone: (state) => state.dashboard.showPhoneModal,
    }),
    rules() {
      return {
        phoneNumber: this.canSubmit === true,
      };
    },
    otprules() {
      return {
        otpValid: this.merchant?.otp?.length === 6,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    disableOtpButton() {
      return Object.values(this.otprules).includes(false);
    },
  },
  methods: {
    ...mapActions("dashboard", ["showPhoneModal"]),
    ...mapActions("notification", ["showAlert", "showToast"]),
    checkPhoneNumber(number, obj) {
      this.phoneIsValid = obj.valid;
      if (this.phoneIsValid) {
        this.canSubmit = true;
      } else {
        this.canSubmit = false;
      }
    },
    initiatePhoneVerification() {
      this.merchant.otp = "";
      this.loading = true;
      INITIATE_PHONE_OTP(this.merchant.phone)
        .then(() => {
          this.showPhoneModal(false);
          this.loading = false;
          this.showOtpModal = true;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    resendPhoneOtp() {
      this.resendLoading = true;
      INITIATE_PHONE_OTP(this.merchant.phone)
        .then((res) => {
          this.resendLoading = false;
          this.showToast({
            display: true,
            description: "A new OTP has been sent",
            icon: "success",
          });
          this.showResendButton = false;
          this.handleDelayOtpButton();
        })
        .catch(() => {
          this.resendLoading = false;
        });
    },
    handleDelayOtpButton() {
      setTimeout(() => {
        this.showResendButton = true;
      }, 30000);
    },
    verifyPhone() {
      this.verifyLoading = true;
      VERIFY_PHONE(this.merchant)
        .then(() => {
          this.verifyLoading = false;
          this.showOtpModal = false;
          this.showAlert({
            display: true,
            description: "Your phone number has been successfully updated",
            type: "success",
          });
        })
        .catch(() => {
          this.verifyLoading = false;
        });
    },
    closeOtpModal() {
      this.showPhoneModal(true);
      this.showResendButton = false;
      this.showOtpModal = false;
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style scoped>
.resend-otp {
  position: absolute;
  top: -0.2rem;
  right: 0;
}
</style>
