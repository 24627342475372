<template>
  <div>
    <img
      class="h-24 w-24 md:h-32 md:w-32 mx-auto md:mt-12"
      src="https://res.cloudinary.com/zillaafrica/image/upload/v1624100575/customer/Group_707_1_tpsp0o.svg"
      alt="success"
    />

    <h4 class="mt-8 text-lg md:text-xl font-semibold text-center text-black">
      The order ({{ orderCode }}) has been created
    </h4>
    <p class="mt-4 text-black md:text-lg text-center">
      Share the link below with your customer and inform them to follow the link
      to complete payment
    </p>
    <div class="my-6 justify-center md:flex items-center">
      <input
        type="text"
        disabled
        class=" rounded-lg p-2.5 mr-4 w-full"
        v-model="paymentLink"
      />
      <Button
        text="Copy"
        p="px-6 py-2"
        @click="copy(paymentLink)"
        width="w-full md:w-44"
        class="mt-3 md:mt-0"
      />
    </div>
  </div>
</template>
<script>
import Button from "@/UI/Button";
import copy from "@/mixins/copy";

export default {
  mixins: [copy],
  components: {
    Button,
  },
  props: ["orderCode", "paymentLink"],
};
</script>
<style scoped>
input {
  background: #f2f2f2;
}
</style>
