export default {
  SHOW_ORDER_MODAL(state, payload) {
    state.showOrderModal = payload;
  },
  SHOW_ORDER_SUCCESS_MODAL(state, payload) {
    state.orderSuccess = payload;
  },
  GET_ORDERS(state, payload) {
    state.orders = payload;
  },
  ADD_ORDERS(state, payload) {
    state.orders.push(...payload);
  },
  GET_PURCHASES(state, payload) {
    state.purchases = payload;
  },
  ADD_PURCHASES(state, payload) {
    state.purchases.push(...payload);
  },
};
