export default {
  GET_PURCHASES(state, payload) {
    state.purchases = payload;
  },
  ADD_PURCHASES(state, payload) {
    state.purchases.push(...payload);
  },
  UPDATE_REFUND_BREAKDOWN(state, payload) {
    state.refundBreakdown = payload;
  },
  CLEAR_BREAKDOWN(state) {
    state.refundBreakdown = {};
  },
};
