<template>
  <LargeModal :display="display" @close="close">
    <div class="container-body__content w-10/12 lg:w-4/12 mx-auto">
      <transition name="fadeIn" mode="out-in">
        <Business v-if="tab === 1" @next="tab += 1" />
        <Documents v-if="tab == 2" @next="tab += 1" />
        <!-- <Director v-if="tab == 3" @next="tab += 1" /> -->
        <Success v-if="tab == 3" @next="close" />
      </transition>
    </div>
  </LargeModal>
</template>
<script>
import Input from "@/UI/Input";
import Button from "@/UI/Button";
import LargeModal from "@/UI/LargeModal";
import Business from "./Business";
import Documents from "./Documents";
// import Director from "./Director";
import Success from "./Success";
import { mapActions } from "vuex";
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Input,
    Button,
    LargeModal,
    Business,
    Documents,
    // Director,
    Success
  },
  data: () => ({
    tab: 1
  }),
  methods: {
    ...mapActions("notification", ["showAlert"]),
    close() {
      if (this.tab > 1) {
        this.$router.push("/");
        // this.showAlert({
        //   display: true,
        //   description:
        //     "Your account is currently on sandbox/test mode pending approval.",
        //   type: "error"
        // });
      }
      this.$emit("close");
    }
  }
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.container-body__content {
  /* position: absolute; */
  max-width: 30rem;
  height: calc(100% - 1rem);
  overflow: auto !important;
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .container-body__content {
    position: absolute;
    padding-left: 1rem;
  }
}
</style>
