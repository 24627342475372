<template>
  <transition name="slideUp">
    <div class="container-body p-4 relative" v-if="display">
      <!-- <div class="flex justify-end"> -->
      <img
        class="h-6 w-6 cursor-pointer"
        src="https://res.cloudinary.com/zillaafrica/image/upload/v1623525601/customer/Union_yx6ulk.svg"
        alt="cancel"
        @click="$emit('close')"
      />
      <div>
        <slot />
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    display(value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.container-body {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  width: 100%;
  background-color: #f6f7ff;
  z-index: 8;
}
.completed {
  background-color: #c70039;
}
img {
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.line {
  background: linear-gradient(
    90deg,
    #c70039 3.72%,
    rgba(184, 184, 184, 0) 108.64%
  );
  border-radius: 100px;
}
</style>
