import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
// import "./plugins/freshDesk";
import "./plugins/filters";
import "@/plugins/tooltip/tooltip.js";
import "@/plugins/tooltip/tooltip.css";
import "./index.css";
import Auth from "@/Layouts/auth.vue";
import Default from "@/Layouts/default.vue";
import Bare from "@/Layouts/bare.vue";
import Card from "@/UI/Card";
import Skeleton from "@/UI/Skeleton";
import VueCookies from "vue-cookies";
import { v4 as uuidv4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "./plugins/atlas";
import "@/plugins/clarity";


const cookieName = "cookieName";
const encryptionToken = VueCookies.get(cookieName) || uuidv4();
VueCookies.set(cookieName, encryptionToken, { secure: true, expires: 180 });

const firebaseConfig = {
  apiKey: "AIzaSyD0VNlHZ9ZFpfe8QASkJU6vtjeQwMDAcsA",
  authDomain: "zilla-merchant-webapp.firebaseapp.com",
  projectId: "zilla-merchant-webapp",
  storageBucket: "zilla-merchant-webapp.appspot.com",
  messagingSenderId: "55582411865",
  appId: "1:55582411865:web:15d8c3db628537a91d7548",
  measurementId: "G-1QXR4J4GQE",
};

if (store?.state?.auth?.user?.merchantUser) {
  Sentry.setUser({ email: store?.state?.auth?.user?.email });
  window && window["clarity"] && window["clarity"]("set", "email", store?.state?.auth?.user?.merchantUser?.email );
  window && window["clarity"] && window["clarity"]("set", "id", store?.state?.auth?.user?.merchantUser?.id );
}

// Layout components
Vue.component("auth-layout", Auth);
Vue.component("default-layout", Default);
Vue.component("bare-layout", Bare);

// UI components
Vue.component("Card", Card);
Vue.component("Skeleton", Skeleton);

Vue.use(VueCookies);

// google Analytics
initializeApp(firebaseConfig);
process.env.VUE_APP_ENVIRONMENT.toString() === "production" && getAnalytics();
// 
process.env.VUE_APP_ENVIRONMENT.toString() === "production" && window.Atlas.start();


const token = VueCookies.get("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
Vue.prototype.$axios = axios;
Vue.prototype.$analytics = getAnalytics();

// sentry config
Sentry.init({
  Vue,
  environment:
    process.env.VUE_APP_ENVIRONMENT.toString(),
  dsn:
    "https://40987604774546f7be65b2f634799ecb@o1117373.ingest.sentry.io/4504281757646848",
  attachStacktrace: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "my-site-url.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
