<template>
  <Card p="p-6 md:py-10 md:px-8">
    <div v-if="loader">
      <Skeleton height="1.3rem" width="8rem" class="mb-3" />

      <div v-for="index in 2" :key="index" class="my-5 mr-2">
        <Skeleton height="1rem" width="7rem" class="mt-8" />
        <Skeleton height="2rem" width="10.2rem" />
        <Skeleton height="1rem" width="100%" class="mt-8" />
        <Skeleton height="1rem" width="7rem" />
      </div>
      <Skeleton height="3rem" width="15rem" class="mt-8" />
    </div>

    <div v-else>
      <transition name="fade" mode="out-in">
        <div>
          <h2 class="font-bold md:text-lg">Your balances</h2>

          <p class="mt-8 md:font-semibold text-sm">Ledger balance</p>
          <h3 class="font-bold md:font-normal text-xl md:text-2xl my-3">
            {{ ledger.balance | formatMoney }}
          </h3>
          <p class="text-sm text-secondary">
            Your ledger balance is how much you have made. At the end of each
            day, you will be settled.
          </p>
          <!-- <Hr class="my-6" /> -->
          <p class="md:font-semibold text-sm md:mt-16 mt-8">
            Available balance
          </p>
          <div class="flex flex-wrap justify-between items-center mt-3">
            <h3 class="font-bold md:font-normal text-xl md:text-2xl mb-3">
              {{ available.balance | formatMoney }}
            </h3>
            <div
              class="flex items-center cursor-pointer"
              @click="showCashoutHistory = true"
            >
              <p class="text-sm mr-1.5 text-brandPurple">
                View cashout history
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M1.33366 6.66583H8.78032L5.52699 9.91917C5.26699 10.1792 5.26699 10.6058 5.52699 10.8658C5.78699 11.1258 6.20699 11.1258 6.46699 10.8658L10.8603 6.4725C11.1203 6.2125 11.1203 5.7925 10.8603 5.5325L6.47366 1.1325C6.21366 0.8725 5.79366 0.8725 5.53366 1.1325C5.27366 1.3925 5.27366 1.8125 5.53366 2.0725L8.78032 5.3325H1.33366C0.966992 5.3325 0.666992 5.6325 0.666992 5.99917C0.666992 6.36583 0.966992 6.66583 1.33366 6.66583Z"
                  fill="#6956C8"
                />
              </svg>
            </div>
          </div>
          <p class="text-sm text-secondary">
            Money here can be moved to your registered settlement account. Only
            two transfers can be done daily.
          </p>

          <div class="flex flex-wrap items-center md:mt-10 mt-6">
            <SecondaryButton
              text="Withdraw from Zilla"
              p="px-8 py-3"
              class="mr-5"
              @click="initiateWithdrawal"
            />
            <p
              @click="fundMerchantWallet()"
              class="text-brandPurple text-sm font-semibold cursor-pointer"
            >
              Fund wallet
            </p>
          </div>

          <Modal
            :display="showWithdrawModal"
            @close="(showWithdrawModal = false), (withdraw = {})"
          >
            <div class="form" v-if="noCashoutAccount === false">
              <h4 class="text-lg font-semibold text-black">
                Withdraw from Zilla
              </h4>
              <CurrencyInput
                width="w-full"
                placeholder="Withdrawal Amount"
                class="mt-12"
                v-model="withdraw.amount"
                errorText="Amount must be greater than ₦100"
                :validation="rules.amount"
              />
              <Input
                width="w-full"
                placeholder="Notes"
                class="mt-10"
                v-model="withdraw.note"
              />
              <Input
                width="w-full"
                placeholder="Cashout Bank"
                class="mt-10"
                disabled="disabled"
                :value="bank.number + ' ' + bank.bank.prettyName"
              />
              <p class="pending my-4 text-sm">
                Zilla will charge you N100 for this withdrawal
              </p>
              <Button
                text="Withdraw"
                width="w-full"
                class="my-12"
                @click="withdrawFromZilla()"
                :loading="loading"
                :disabled="disableButton"
              />
            </div>
            <div v-else class="my-10">
              <img
                src="https://res.cloudinary.com/zillaafrica/image/upload/q_auto/v1631996266/checkout/50_1_o6ver8.png"
                alt="Order Invalid"
                class="mx-auto h-36"
              />
              <p class="text-center text-xl font-semibold my-10">
                You need to provide a bank account for withdrawal
              </p>
              <Button
                text="Add an account"
                :shadow="true"
                p="p-3"
                class="mt-12 mx-auto"
                width="w-full"
                @click="addAccount()"
              />
            </div>
          </Modal>
        </div>
      </transition>
    </div>

    <Modal
      :display="showCashoutHistory"
      @close="showCashoutHistory = false"
      title="Cashout history"
    >
      <CashoutHistory />
    </Modal>
    <Modal
      :display="showFundAccount"
      @close="closeFundAccount"
      title="Add money to account"
    >
      <FundAccount @close="closeFundAccount" />
    </Modal>
  </Card>
</template>
<script>
import { WITHDRAW_FROM_ZILLA } from "@/utils/api/overview.js";
import { GET_USER_CASHOUT_ACCOUNT } from "@/utils/api/settings.js";
import { mapState, mapActions } from "vuex";
import Button from "@/UI/Button";
import SecondaryButton from "@/UI/SecondaryButton";
import Modal from "@/UI/Modal";
import Input from "@/UI/Input";
import CurrencyInput from "@/UI/CurrencyInput";
import Hr from "@/UI/Hr";

export default {
  components: {
    Button,
    SecondaryButton,
    Modal,
    Input,
    CurrencyInput,
    Hr,
    CashoutHistory: () => import("../CashoutHistory"),
    FundAccount: () => import("../FundAccount")
  },
  data: () => ({
    showWithdrawModal: false,
    loader: false,
    withdraw: {},
    loading: false,
    bank: {
      bank: {}
    },
    showCashoutHistory: false,
    noCashoutAccount: false,
    showFundAccount: false
  }),
  mounted() {
    this.getAccountInfo();
    this.getWalletInfo();
  },
  computed: {
    ...mapState({
      merchantOutletId: state => state?.auth?.user?.merchantOutlet?.id,
      liveMode: state => state?.auth?.liveMode,
      ledger: state => state?.dashboard?.wallet?.salesWallet,
      available: state => state?.dashboard?.wallet?.settlementWallet,
      activatedStore: state => state?.auth?.activatedStore
    }),
    rules() {
      return {
        amount: this.withdraw.amount > 100
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    }
  },
  methods: {
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("auth", ["setCurrentTab"]),
    ...mapActions("dashboard", ["getMerchantWallet"]),

    getWalletInfo() {
      this.loader = true;
      this.getMerchantWallet({
        id: this.merchantOutletId,
        liveMode: this.liveMode
      })
        .then(res => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },

    closeFundAccount(e) {
      this.showFundAccount = false;
      if (e === "done") {
        this.getWalletInfo();
      }
    },

    getAccountInfo() {
      GET_USER_CASHOUT_ACCOUNT(this.merchantOutletId)
        .then(response => {
          if (response.data.data) {
            this.bank = response.data.data;
          }
        })
        .catch(err => {
          if (err.response.data.message === "Cashout bank account not found") {
            this.noCashoutAccount = true;
          }
        });
    },

    withdrawFromZilla() {
      this.loading = true;
      WITHDRAW_FROM_ZILLA({
        id: this.merchantOutletId,
        data: this.withdraw,
        liveMode: this.liveMode
      })
        .then(res => {
          this.loading = false;
          this.getWalletInfo();
          if (res.data.data.status === "SUCCESS") {
            this.showAlert({
              display: true,
              type: "success",
              description: `Your withdrawal was successful`
            });
          } else if (res.data.data.status === "FAILED") {
            this.showAlert({
              description:
                "Your attempt to withdraw from your wallet failed, please contact support.",
              display: true,
              type: "error"
            });
          } else {
            this.showAlert({
              description:
                "Your withdrawal request is still processing. Please try again if you have not been funded after a while.",
              display: true,
              type: "success"
            });
          }
          this.showWithdrawModal = false;
        })
        .catch(() => {
          // this.showWithdrawModal = false;
          this.loading = false;
        });
    },

    initiateWithdrawal() {
      if (!this.liveMode) {
        this.showAlert({
          description:
            "Your account is currently on SANDBOX mode. Switch to LIVE mode to access this feature",
          display: true,
          type: "error"
        });
      } else {
        this.showWithdrawModal = true;
      }
    },

    fundMerchantWallet() {
      if (!this.liveMode) {
        this.showAlert({
          description:
            "Your account is currently on SANDBOX mode. Switch to LIVE mode to access this feature",
          display: true,
          type: "error"
        });
      } else {
        this.showFundAccount = true;
      }
    },

    addAccount() {
      this.setCurrentTab("account");
      this.$router.push("/settings");
    }
  },
  watch: {
    liveMode() {
      this.getAccountInfo();
      this.getWalletInfo();
    }
  }
};
</script>
