export default {
  UPDATE_WEBHOOK_INFO(state, payload) {
    state.webhookInfo = payload;
  },
  SHOW_ENABLE_WEBHOOK(state, payload) {
    state.showEnableWebhook = payload;
  },
  SHOW_WEBHOOK_FORM(state, payload) {
    state.showWebhookForm = payload;
  }
};
