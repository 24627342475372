export default {
  SET_REGISTRATIONID(state, registrationId) {
    state.registrationId = registrationId;
  },
  CHANGE_PASSWORD_ID(state, payload) {
    state.password_id = payload;
  },
  setUserDetails(state, user) {
    state.user = user;
  },
  setCurrentTab(state, tab) {
    state.currentTab = tab;
  },

  SET_PORT_NAME(state, payload) {
    state.portName = payload;
  },

  MERCHANT_LOGO(state, payload) {
    state.user.merchant.logo = payload;
  },
  setMerchantDetails(state, payload) {
    state.user.merchant = payload;
  },
  setMerchantBankDetails(state, payload) {
    state.user.merchantOutlet = payload;
  },
  AUTH_REQUEST(state) {
    state.status = "loading";
  },
  AUTH_SUCCESS(state, token, sandboxToken) {
    state.status = "success";
    state.token = token;
    state.sandboxToken = sandboxToken;
  },
  AUTH_ERROR(state) {
    state.status = "error";
  },
  AUTH_LOGOUT(state) {
    state.token = "";
    state.activatedStoreModal = false;
  },
  ACTIVATE_STORE(state, payload) {
    state.activatedStore = payload;
  },
  SET_ACTIVATE_STORE_MODAL(state, payload) {
    state.activatedStoreModal = payload;
  },
  SET_KYC_STATUS(state, payload) {
    state.kycStatus = payload;
  },
  SET_KYC_STATUS_MODAL(state, payload) {
    state.kycStatusModal = payload;
  },
  SET_LIVE_MODE(state, payload) {
    state.liveMode = payload;
  },
  SET_TOKEN_EXPIRE(state, payload) {
    state.tokenExpire = payload;
  },

  // temporary pending sandbox fix
  SET_SWITCH(state, payload) {
    state.switchMode = payload;
  },
};
