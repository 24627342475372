<template>
  <div>
    <transition name="fade" mode="out-in">
      <Metrics />
    </transition>
    <div class="mt-4 overview-container md:grid grid-cols-2 gap-4">
      <Balance />
      <Barcode />
    </div>
    <Modal :display="activatedStore && kycStatusModal" @close="closeModal">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="69"
          height="68"
          viewBox="0 0 69 68"
          fill="none"
          class="mx-auto"
          v-if="kycStatus === 'PENDING'"
        >
          <rect x="0.285156" width="68" height="68" rx="34" fill="#FEFBE7" />
          <path
            d="M38.1781 19.7296L38.1781 19.7296C36.4455 16.7369 32.1253 16.7369 30.3927 19.7296L30.3927 19.7296L15.3327 45.7496C13.6 48.7425 15.7597 52.5001 19.2254 52.5001H49.3454C52.8111 52.5001 54.9708 48.7425 53.2381 45.7496L38.1781 19.7296ZM34.2854 37.5001C33.4615 37.5001 32.7854 36.824 32.7854 36.0001V32.0001C32.7854 31.1762 33.4615 30.5001 34.2854 30.5001C35.1093 30.5001 35.7854 31.1762 35.7854 32.0001V36.0001C35.7854 36.824 35.1093 37.5001 34.2854 37.5001ZM32.7854 45.5001V42.5001H35.7854V45.5001H32.7854Z"
            fill="#CD9C25"
            stroke="#CD9C25"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="69"
          height="68"
          viewBox="0 0 69 68"
          fill="none"
          class="mx-auto"
          v-else-if="kycStatus === 'DECLINED'"
        >
          <rect x="0.285156" width="68" height="68" rx="34" fill="#FFFAF8" />
          <path
            d="M36.2852 44.5H36.7852V44V40V39.5H36.2852H32.2852H31.7852V40V44V44.5H32.2852H36.2852ZM36.7852 26C36.7852 24.6239 35.6613 23.5 34.2852 23.5C32.909 23.5 31.7852 24.6239 31.7852 26V34C31.7852 35.3761 32.909 36.5 34.2852 36.5C35.6613 36.5 36.7852 35.3761 36.7852 34V26ZM34.2652 13.5C22.9484 13.5 13.7852 22.6845 13.7852 34C13.7852 45.3155 22.9484 54.5 34.2652 54.5C45.601 54.5 54.7852 45.3165 54.7852 34C54.7852 22.6835 45.601 13.5 34.2652 13.5ZM34.2852 49.5C25.7213 49.5 18.7852 42.5639 18.7852 34C18.7852 25.4361 25.7213 18.5 34.2852 18.5C42.849 18.5 49.7852 25.4361 49.7852 34C49.7852 42.5639 42.849 49.5 34.2852 49.5Z"
            fill="#DE5F35"
            stroke="#DE5F35"
          />
        </svg>
        <h2 class="text-center text-2xl font-bold mt-4">
          {{
            kycStatus === "PENDING" ? "Your store is in review" : "We are sorry"
          }}
        </h2>
        <p v-if="kycStatus === 'PENDING'" class="text-center text-dark pt-4">
          We are currently reviewing your store based on the details submitted
          to us. Please check back after some time.
        </p>
        <p
          v-else-if="kycStatus === 'DECLINED'"
          class="text-center text-dark pt-4"
        >
          Unfortunately, we are unable to verify your business, and as a result,
          your Zilla merchant account.
          <br />If you think we have made a mistake, please let us know.
        </p>
        <Button
          text="Okay"
          width="w-full"
          class="mb-4 mt-6"
          @click="closeModal()"
        />
        <p
          v-if="kycStatus === 'DECLINED'"
          @click="closeModal()"
          class="text-center cursor-pointer text-brandPurple font-semibold"
        >
          Cancel
        </p>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Metrics from "./Metrics";
import Balance from "./Balance";
import Barcode from "./Barcode";
import Modal from "@/UI/Modal";
import Button from "@/UI/Button";
export default {
  components: {
    Metrics,
    Balance,
    Barcode,
    Modal,
    Button
  },
  computed: {
    ...mapState({
      kycStatus: state => state?.auth?.kycStatus,
      activatedStore: state => state?.auth?.activatedStore,
      kycStatusModal: state => state?.auth?.kycStatusModal
    })
  },
  methods: {
    ...mapActions("auth", ["resetKycStatus"]),
    closeModal() {
      this.resetKycStatus();
    }
  }
};
</script>
<style scoped>
.overview-container {
  height: calc(100vh - 20rem);
}
</style>
