import axios from "@/plugins/axios";

export const LOGIN = (data) => {
  return axios.post(`/bnpl/auth`, data);
};

export const REGISTER = (data) => {
  return axios.post(`/bnpl/merchant-registration`, data);
};

export const SUBMIT_PASSWORD = (data) => {
  return axios.put(`/bnpl/merchant-registration/submit-password`, data);
};

export const SUBMIT_BUSINESS_INFO = (data) => {
  return axios.put(`/bnpl/merchant-registration/submit-business-info`, data);
};

export const FORGOT_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/initiate-password-reset`, data);
};

export const PASSWORD_OTP = (data) => {
  return axios.put(`/volt/password-change/submit-password-reset-otp`, data);
};

export const PHONE_OTP = (phoneNumber) => {
  return axios.put(`/bnpl/merchant-registration/phone/${phoneNumber}/send-otp`);
};

export const INITIATE_PHONE_OTP = (phoneNumber) => {
  return axios.put(`/bnpl/merchant/phone/${phoneNumber}/send-otp`);
};

export const VERIFY_PHONE = (data) => {
  return axios.put(`/bnpl/merchant/verify-phone`, data);
};

export const PASSWORD_RESET = (data) => {
  return axios.put(`/volt/password-change/complete-password-reset`, data);
};

export const NEW_PASSWORD = (data) => {
  return axios.put(`/volt/password-change/change-password`, data);
};

export const GET_MERCHANT = () => {
  return axios.get(`/bnpl/merchant/login-user`);
};

export const VERIFY_OTP = (id) => {
  return axios.put(`/volt/password-change/${id}/resend-otp`);
};

export const PORT_CUSTOMER = (data) => {
  return axios.post(`/bnpl/merchant-registration/port-customer`, data);
};
