<template>
  <div>
    <div @click="isStoreActivated()" class="mt-0">
      <SwitchInput
        v-tooltip.bottom="{
          content: `${
            liveMode
              ? 'Your store is live and you can collect Zilla payments from your customers'
              : 'This is a test environment. You can switch to live from here'
          }`
        }"
        id="tfa"
        v-model="liveMode"
        disabled
      />
    </div>
    <Modal :display="showActivateModal" @close="closeModal()">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="69"
          height="68"
          viewBox="0 0 69 68"
          fill="none"
          class="mx-auto"
        >
          <rect x="0.285156" width="68" height="68" rx="34" fill="#FFFAF8" />
          <path
            d="M36.2852 44.5H36.7852V44V40V39.5H36.2852H32.2852H31.7852V40V44V44.5H32.2852H36.2852ZM36.7852 26C36.7852 24.6239 35.6613 23.5 34.2852 23.5C32.909 23.5 31.7852 24.6239 31.7852 26V34C31.7852 35.3761 32.909 36.5 34.2852 36.5C35.6613 36.5 36.7852 35.3761 36.7852 34V26ZM34.2652 13.5C22.9484 13.5 13.7852 22.6845 13.7852 34C13.7852 45.3155 22.9484 54.5 34.2652 54.5C45.601 54.5 54.7852 45.3165 54.7852 34C54.7852 22.6835 45.601 13.5 34.2652 13.5ZM34.2852 49.5C25.7213 49.5 18.7852 42.5639 18.7852 34C18.7852 25.4361 25.7213 18.5 34.2852 18.5C42.849 18.5 49.7852 25.4361 49.7852 34C49.7852 42.5639 42.849 49.5 34.2852 49.5Z"
            fill="#DE5F35"
            stroke="#DE5F35"
          />
        </svg>
        <h2 v-if="!activatedStore" class="text-center text-2xl font-bold mt-4">
          Your store has not been activated yet
        </h2>
        <h2
          v-else-if="activatedStore && kycStatus === 'PENDING'"
          class="text-center text-2xl font-bold mt-4"
        >
          Your store is pending confirmation
        </h2>
        <h2
          v-else-if="activatedStore && kycStatus === 'DECLINED'"
          class="text-center text-2xl font-bold mt-4"
        >
          Your store was declined
        </h2>
        <p v-if="!activatedStore" class="text-center text-dark pt-4">
          Your store is currently on sandbox. This is a test environment and you
          would not be able to perform real transactions yet in this mode. To
          start selling, do the following:
        </p>
        <p
          v-else-if="activatedStore && kycStatus === 'PENDING'"
          class="text-center text-dark pt-4"
        >
          We are still verifying your account. Please give us some time.
        </p>
        <p
          v-else-if="activatedStore && kycStatus === 'DECLINED'"
          class="text-center text-dark pt-4"
        >
          Unfortunately, we are unable to verify your business, and as a result,
          your Zilla merchant account.
          <br />If you think we have made a mistake, please let us know.
        </p>
        <ul v-if="!activatedStore" class="p-4 list-disc">
          <li>
            Activate your store and get confirmed by us within 1 working day
          </li>
        </ul>
        <Button
          :text="!activatedStore ? 'Activate store' : 'Okay'"
          width="w-full"
          class="mb-4 mt-6"
          @click="!activatedStore ? openActivateStore() : closeModal()"
        />
        <p
          v-if="!activatedStore"
          @click="closeModal()"
          class="text-center cursor-pointer text-brandPurple font-semibold"
        >
          Cancel
        </p>
        <div
          class="text-center"
          v-else-if="activatedStore && kycStatus === 'DECLINED'"
        >
          <a
            href="mailto:support@zilla.africa"
            target="blank"
            class="cursor-pointer text-brandPurple font-semibold"
          >
            Contact support
          </a>
        </div>
      </div>
    </Modal>
    <ActivateForm :display="showActivate" @close="showActivate = false" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SwitchInput from "@/UI/SwitchInput";
import {
  GET_BUSINESS_INFO,
  TOGGLE_PROGRAMMATIC_ACCESS
} from "@/utils/api/settings.js";
import ActivateForm from "../components/Activate/ActivateForm";
import Modal from "@/UI/Modal";
import Button from "@/UI/Button";
export default {
  components: {
    SwitchInput,
    ActivateForm,
    Modal,
    Button
  },
  data: () => ({
    switchMode: false,
    openPopover: false,
    showActivate: false,
    showActivateModal: false,
    sandboxDescription:
      "This is a test environment. You can switch to live mode from settings",
    liveDescription:
      "You are now live and can now collect Zilla payments from your customers"
  }),
  computed: {
    ...mapState({
      merchant: state => state?.auth?.user?.merchant,
      liveMode: state => state?.auth?.liveMode,
      activatedStore: state => state?.auth?.activatedStore,
      settingType: state => state?.auth?.currentTab,
      kycStatus: state => state?.auth?.kycStatus
    })
  },
  methods: {
    ...mapActions("auth", ["setMode", "setKycStatus", "resetKycStatus"]),
    ...mapActions("notification", ["showToast"]),
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("settings", ["getWebhookInfo"]),

    openActivateStore() {
      this.$router.push({ name: "Activate" }).catch(() => {});
      this.showActivateModal = false;
      this.showActivate = true;
    },
    isStoreActivated() {
      if (!this.activatedStore) {
        this.showActivateModal = true;
      } else {
        this.getBusinessInfo();
      }
    },
    getBusinessInfo() {
      this.setLoading(true);
      GET_BUSINESS_INFO()
        .then(response => {
          this.setLoading(false);
          if (response.data.data) {
            let status = response.data.data.kycStatus;
            this.setKycStatus(status);
            if (status === "APPROVED") {
              this.switchLiveMode();
            } else if (status === "PENDING" || status === "DECLINED") {
              this.showActivateModal = true;
              this.setMode(false);
            }
          }
        })
        .catch(() => {
          this.setLoading(false);
          this.loader = false;
        });
    },
    switchLiveMode() {
      this.setLoading(true);
      TOGGLE_PROGRAMMATIC_ACCESS({
        newDashboardMode: this.liveMode ? "SANDBOX" : "LIVE"
      })
        .then(() => {
          this.setMode(!this.liveMode);
          this.showToast({
            description: this.liveMode
              ? "Live mode turned on"
              : "Live mode turned off",
            display: true,
            icon: "success"
          });
          if (this.settingType === "api") {
            this.getWebhookInfo({ liveMode: this.liveMode });
          }
          setTimeout(() => {
            this.setLoading(false);
          }, 2000);
        })
        .catch(error => {
          if (error.response.data.message === "KYC document is required.") {
          }
        });
    },
    closeModal() {
      // this.resetKycStatus();
      this.showActivateModal = false;
    }
  }
};
</script>

<style>
</style>