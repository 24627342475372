<template>
  <div class="md:flex justify-between items-center md:py-7 py-4 pl-6 pr-8">
    <div>
      <h3 class="md:text-xl font-bold text-darkBlue">
        Hello {{ ActiveUser.merchantUser.firstName | capitalize }},
        <br class="md:hidden" />
        <span class="font-normal">{{ greeting }}</span>
      </h3>
    </div>
    <div class="hidden md:flex items-center">
      <div class="flex mr-6">
        <span v-if="!liveMode" class="text-grey mr-2">Sandbox</span>
        <SandboxSwitch />
        <span v-if="liveMode" class="text-grey ml-2">Live</span>
      </div>
      <div
        class="flex items-center cursor-pointer mr-3"
        @click="showOrderModal(true)"
      >
        <p class="text-sm mr-1.5 font-semibold text-brandPurple">
          Create Payment Link
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M11.333 4.66602H9.33301C8.96634 4.66602 8.66634 4.96602 8.66634 5.33268C8.66634 5.69935 8.96634 5.99935 9.33301 5.99935H11.333C12.433 5.99935 13.333 6.89935 13.333 7.99935C13.333 9.09935 12.433 9.99935 11.333 9.99935H9.33301C8.96634 9.99935 8.66634 10.2993 8.66634 10.666C8.66634 11.0327 8.96634 11.3327 9.33301 11.3327H11.333C13.173 11.3327 14.6663 9.83935 14.6663 7.99935C14.6663 6.15935 13.173 4.66602 11.333 4.66602ZM5.33301 7.99935C5.33301 8.36602 5.63301 8.66602 5.99967 8.66602H9.99967C10.3663 8.66602 10.6663 8.36602 10.6663 7.99935C10.6663 7.63268 10.3663 7.33268 9.99967 7.33268H5.99967C5.63301 7.33268 5.33301 7.63268 5.33301 7.99935ZM6.66634 9.99935H4.66634C3.56634 9.99935 2.66634 9.09935 2.66634 7.99935C2.66634 6.89935 3.56634 5.99935 4.66634 5.99935H6.66634C7.03301 5.99935 7.33301 5.69935 7.33301 5.33268C7.33301 4.96602 7.03301 4.66602 6.66634 4.66602H4.66634C2.82634 4.66602 1.33301 6.15935 1.33301 7.99935C1.33301 9.83935 2.82634 11.3327 4.66634 11.3327H6.66634C7.03301 11.3327 7.33301 11.0327 7.33301 10.666C7.33301 10.2993 7.03301 9.99935 6.66634 9.99935Z"
            fill="#6956C8"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Button from "@/UI/Button";
import CreateOrder from "@/components/CreateOrder";
import OrderSuccessModal from "@/components/CreateOrder/OrderSuccessModal";
import { GET_BUSINESS_INFO } from "@/utils/api/settings.js";
import Modal from "@/UI/Modal";
import SandboxSwitch from "./SandboxSwitch.vue";
export default {
  components: {
    Button,
    CreateOrder,
    Modal,
    OrderSuccessModal,
    SandboxSwitch
  },

  data: () => ({
    showCreateOrder: false,
    showOrderSuccess: false,
    greeting: ""
  }),

  computed: {
    ...mapState({
      liveMode: state => state?.auth?.liveMode
    }),
    ActiveUser() {
      return this.$store.getters["auth/ActiveUser"];
    },
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    }
  },

  mounted() {
    this.getGreeting();
    if (this.isAuthenticated) {
      this.getBusinessInfo();
    }
  },

  methods: {
    ...mapActions("order", ["showOrderModal"]),
    ...mapActions("dashboard", ["showPhoneModal"]),
    getGreeting() {
      const time = new Date().getHours();
      if (time < 12) {
        this.greeting = "Good Morning";
      } else if (time < 18) {
        this.greeting = "Good Afternoon";
      } else {
        this.greeting = "Good Evening";
      }
    },
    getBusinessInfo() {
      GET_BUSINESS_INFO()
        .then(response => {
          if (response.data.data.phoneNumber === null) {
            this.showPhoneModal(true);
          }
        })
        .catch(() => {});
    }
  }
};
</script>
