<template>
  <div>
    <!-- create order form modal -->
    <Modal :display="showOrder" @close="showOrderModal(false), (order = {})">
      <div class="form">
        <h4 class="text-lg font-semibold text-black">Create new payment link</h4>
        <Input
          width="w-full"
          placeholder="Title"
          class="mt-8"
          v-model="order.title"
          errorText="Title is required"
          :validation="rules.title"
          @valid="valid.title = $event"
        />
        <CurrencyInput
          width="w-full"
          placeholder="Amount"
          class="mt-8"
          v-model="order.amount"
          errorText="Amount must be a minimum of ₦100"
          :validation="rules.amount"
          @valid="valid.amount = $event"
        />
        <!-- <Input
          width="w-full"
          placeholder="Customer's Zilla ID (optional)"
          class="mt-8"
          v-model="order.customerHandle"
        /> -->
        <Button
          text="Create"
          width="w-full"
          class="my-12"
          @click="createOrder"
          :loading="loading"
          :disabled="disableButton"
        />
      </div>
    </Modal>
    <!-- success modal -->
    <Modal :display="showOrderSuccess" @close="showOrderSuccess = false">
      <OrderSuccess
        :orderCode="orderDetails.orderCode"
        :paymentLink="orderDetails.paymentLink"
      />
    </Modal>
  </div>
</template>
<script>
import { CREATE_ORDER } from "@/utils/api/order.js";
import { mapActions, mapState } from "vuex";
import Input from "@/UI/Input";
import CurrencyInput from "@/UI/CurrencyInput";
import Button from "@/UI/Button";
import Modal from "@/UI/Modal";
import OrderSuccess from "./OrderSuccessModal";
export default {
  components: {
    Input,
    Button,
    Modal,
    OrderSuccess,
    CurrencyInput
  },
  data: () => ({
    loading: false,
    order: {
      title: "",
      amount: 0
    },
    showOrderSuccess: false,
    orderDetails: {},
    valid: {
      title: false,
      amount: false
    }
  }),
  computed: {
    ...mapState({
      showOrder: state => state.order.showOrderModal,
      merchantOutletId: state => state?.auth?.user?.merchantOutlet.id,
      liveMode: state => state?.auth?.liveMode
      // showOrderSuccess:(state) => state.order.orderSuccess
    }),
    rules() {
      return {
        title: this.order.title !== "",
        amount: this.order.amount >= 100
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    }
  },
  methods: {
    ...mapActions("order", [
      "showOrderModal",
      "showOrderSuccessModal",
      "getAllOrders"
    ]),
    createOrder() {
      this.order.merchantOutletId = this.merchantOutletId;
      this.loading = true;
      CREATE_ORDER({
        data: this.order,
        liveMode: this.liveMode
      })
        .then(response => {
          this.orderDetails = response.data.data;
          this.loading = false;
          const id = this.merchantOutletId;
          this.getAllOrders({
            id,
            params: {
              spage: 0,
              pageSize: 20,
              createdAtFrom: "",
              createdAtTo: "",
              status: "PENDING",
              orderCode: "",
              orderSource: "DASHBOARD"
            },
            liveMode: this.liveMode
          });
          this.showOrderModal(false), (this.showOrderSuccess = true);
          this.order = {};
        })
        .catch(error => {
          this.loading = false;
          this.showOrderModal(false),
            console.error("There was an error!", error);
        });
    }
  }
};
</script>
