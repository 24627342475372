<template>
  <div class="relative">
    <transition name="slideUp">
      <p class="text-xs placeholder-top absolute -top-4" v-if="showPlaceholder">
        {{ placeholder }}
      </p>
    </transition>

    <div class="relative inline-block" :class="[width]">
      <div v-if="!revealPassword">
        <transition name="fade" v-if="error && !hideErrorIcon">
          <img
            class="absolute w-4 h-4 error-image"
            src="@/assets/icons/inputError.svg"
            alt=""
          />
        </transition>
      </div>
      <div class="absolute eye-wrapper z-5" v-if="revealPassword">
        <div class="eye-container" @click="revealText">
          <!-- <div class="line-stroke" v-show="showEye"></div> -->
          <svg width="23" height="11" fill="none" v-if="showEye">
            <path
              d="M18.845 2.587C19.558 1.7 19.893 1 19.893 1l-1.048 1.587zm0 0c-.677.845-1.696 1.86-3.09 2.606m3.09-2.606l-3.09 2.606m3.09-2.606l3.145 1.558M11.508 6.24A8.887 8.887 0 017.26 5.193m4.247 1.048L7.26 5.193m4.247 1.048a8.887 8.887 0 004.246-1.048M11.508 6.24l4.246-1.048M11.508 6.24V9.91M7.26 5.193L4.17 8.338m3.09-3.145C5.94 4.487 4.958 3.54 4.28 2.724m2.98 2.469L4.28 2.724m11.473 2.469l2.567 3.145M4.281 2.724C3.493 1.772 3.121 1 3.121 1l1.16 1.724zm0 0l-3.256 1.42"
              stroke="#000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg width="23" height="11" fill="none" v-else>
            <path
              d="M4.17 8.326c-.712.888-1.048 1.587-1.048 1.587L4.17 8.326zm0 0c.678-.845 1.697-1.86 3.091-2.606m-3.09 2.606L7.26 5.72m-3.09 2.606L1.025 6.768m10.482-2.096c1.686 0 3.095.432 4.247 1.048m-4.247-1.048l4.247 1.048m-4.247-1.048A8.887 8.887 0 007.261 5.72m4.247-1.048L7.261 5.72m4.247-1.048V1.003m4.247 4.717l3.09-3.144m-3.09 3.144c1.32.706 2.303 1.653 2.98 2.469m-2.98-2.469l2.98 2.469M7.26 5.72L4.695 2.576m14.04 5.613c.788.952 1.159 1.724 1.159 1.724l-1.16-1.724zm0 0l3.255-1.42"
              stroke="#000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <transition name="fade">
        <div class="placeholder absolute" v-if="!showPlaceholder">
          <p class="text-grey">
            {{ placeholder }}
          </p>
        </div>
      </transition>
      <input
        class="outline-none transition-all placeholder-borderGrey"
        :class="[
          p,
          error ? 'error-border' : null,
          width,
          disabled ? 'disabled' : null,
          revealPassword ? 'pr-10' : null,
          type === 'password' ? 'atlas-mask' : null,
        ]"
        @keypress="isNumber($event)"
        @input="$emit('input', $event.target.value)"
        @blur="displayInput"
        @focus="handleFocus"
        @focusout="displayPlaceholder = false"
        @change="$emit('change')"
        :type="displayType"
        :name="name"
        :id="id"
        :value="value"
        :disabled="disabled"
        :required="required"
        :autofocus="autofocus"
        :pattern="type === 'number' ? '[0-9]*' : null"
        :inputmode="type === 'number' ? 'numeric' : 'text'"
      />
    </div>

    <div class="error-text">
      <transition name="fade">
        <p class="text-brandRed text-xs mt-1" v-if="error">
          {{ errorText }}
        </p>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "input",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
    p: {
      type: String,
      default: "py-2",
      required: false,
    },
    value: {
      type: String,
      default: "",
      required: false,
    },
    id: {
      type: String,
      default: "",
      required: false,
    },
    placeholder: {
      type: String,
      default: "",
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    errorText: {
      type: String,
      default: "error",
      required: false,
    },
    validation: {
      type: Boolean,
      default: true,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    revealPassword: {
      type: Boolean,
      default: false,
      required: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: String,
      default: "w-auto",
      required: false,
    },
    number: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideErrorIcon: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      error: false,
      showEye: true,
      showEyeWrapper: false,
      eyeDropper: false,
      displayPlaceholder: false,
      // showPlaceholder: false,
    };
  },
  watch: {
    validation(value) {},
    // type(value){
    //   if(value === 'number'){

    //   }
    // value(value) {
    //   if (value) {
    //   }
    // },
    // showPlaceholder(value) {
    //   console.log(value, this.displayPlaceholder);
    // },
  },
  computed: {
    displayType() {
      return this.eyeDropper || this.type;
    },
    showPlaceholder() {
      if (this.displayPlaceholder || this.value) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    displayInput() {
      if (!this.validation) {
        // console.log("input", this.validation);
        this.error = true;
        this.$emit("valid", false);
      } else {
        this.error = false;
        this.$emit("valid", true);
      }
      this.$emit("blur");
    },
    revealText() {
      if (!this.eyeDropper) {
        this.eyeDropper = "text";
      } else {
        this.eyeDropper = false;
      }
      this.showEye = !this.showEye;
    },
    handleFocus() {
      this.displayPlaceholder = true;
      this.error = false;
      this.$emit("focus");
    },
    isNumber(evt) {
      if (this.type === "number") {
        evt = evt ? evt : window.event;
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (
          (charCode > 31 &&
            (charCode < 48 || charCode > 57) &&
            charCode !== 46) ||
          this.amount > 1000000
        ) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/styles/animation.css";
input {
  border-bottom: 1px solid black;
  font-size: 18px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  -webkit-appearance: none;
  border-radius: 0;
  transition: all 0.3s linear;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input::placeholder {
  color: #9d9d9d;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* input[type="text"] {
    font-size: 16px;
  } */
.placeholder {
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 1;
}
.eye-wrapper {
  right: 0;
  top: 35%;
  cursor: pointer;
  z-index: 8;
}

.eye-container {
  position: relative;
}
.line-stroke {
  position: absolute;
  height: 2rem;
  width: 0.15rem;
  background-color: #b5b5b5;
  top: -0.35rem;
  transform: rotate(-45deg);
  left: 50%;
}
.error-border {
  border-bottom: 1px solid #c70039;
}
.error-text {
  top: 2.5rem;
}
.disabled {
  border-bottom: 1px solid #f2edfd;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .error-text {
    top: 2.5rem;
  }

  input {
    font-size: 16px;
  }
  /* .eye-wrapper {
    top: 15%;
  } */
}
.error-image {
  right: 0;
  top: 22%;
  z-index: 2;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.45, 0.25, 0.6, 0.95);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
